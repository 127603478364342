import React from 'react'
import Dashboard from '../../pages/Dashboard/Dashboard';
import { AccomodationIcon } from './ApplicationIcon'
import { useNavigate } from 'react-router-dom';


const EmployeeDetails = ({tabsData, tabActiveData, setTabActiveData,}) => {
  const navigate=useNavigate();
  return (
    <Dashboard>
            <div className='aod-outer'>
                <div className="aod-inner">
                    <div className="aod-top">
                        {tabsData?.map((tab) => {
                            return (

                                <button className={tabActiveData?.id === tab?.id ? 'green' : 'white'} onClick={() => { setTabActiveData(tab); navigate(`/application/${tab?.tableName?.replace(/_/g, '-')}`) }}><AccomodationIcon />{tab?.form_name_en}</button>
                            )
                        })}

                    </div>
                    {/* <div className="aod-bottom" >
                        {inputFormData?.sections.filter((section) => section.section_key !== 'owners_partners') // Filter out the third section
                            .map((section) => (
                                <>
                                    <div className="aod-head">{section?.name_en}</div>
                                    <div className="row py-4">
                                        {section.category_form_feilds?.map(field => (
                                            <div className="col-md-4" key={field.id}>
                                                <div className='suf-input-box aod-form-input mb-2'>
                                                    <label className='mb-1 w-100'>{field.name_en}</label>
                                                    {field?.type === 'text' ?
                                                        <>
                                                            <input
                                                                className='w-100'
                                                                // onChange={(e) => handleFieldChange(e, item?.id)}
                                                                value={field?.value || " "}
                                                                onKeyUp={() => handleKeyUp(field, section)}


                                                                placeholder={`Enter ${field.name_en}`}
                                                                type="text"
                                                                name={field.input_field_name}
                                                                onChange={(e) => {
                                                                    if (field.id === 12) {
                                                                        handlePartnersChange(e, field?.id, section?.id);
                                                                    } else if (field.id === 8) {
                                                                        handleHouseNumberChange(e, field?.id, section?.id);
                                                                    } else if (field.id === 10) {
                                                                        handleOrganizationWebsiteChange(e, field?.id, section?.id);
                                                                    } else {
                                                                        handleFieldChange(e, field?.id, section?.id);
                                                                    }
                                                                }}
                                                            />
                                                            {inputFieldErrorMessage[`${section.id}-${field.id}`] && (
                                                                <p className='validate-error'>{inputFieldErrorMessage[`${section.id}-${field.id}`]}</p>
                                                            )}
                                                        </> : null

                                                    }
                                                    {field.type === 'dropdown' &&
                                                        (field?.id === 5 ? (
                                                            <>
                                                                <Select
                                                                    className='menu_open_select'
                                                                    classNamePrefix='menu_open_select_class'
                                                                    value={field?.value ? { value: field?.value, label: statesData?.find(item => item.id === field?.value)?.name } : null}
                                                                    aria-label="Default select example"
                                                                    onChange={(e) => handleDropdownChangeFirst(e, "state_id", field?.id, section?.id)}
                                                                    options={statesData?.map((state) => ({ label: state.name, value: state.id }))}
                                                                    onMenuOpen={() => {
                                                                        setClickDropdown({ ...clickDropdown, state_id: true })


                                                                        // if (!valueStore[`${section?.id}-${field?.id}`]) {
                                                                        //     setInputFieldErrorMessage((prevErrors) => ({ ...prevErrors, state_id: "Please select State" }));

                                                                        // }
                                                                        if (!valueStore[`${section?.id}-${field?.id}`]) {
                                                                            setInputFieldErrorMessage((prevErrors) => ({
                                                                                ...prevErrors,
                                                                                [`${section.id}-${field?.id}`]: "Please select state"
                                                                            }));

                                                                        }
                                                                    }
                                                                    }
                                                                    placeholder="Select State"
                                                                />
                                                                {inputFieldErrorMessage[`${section.id}-${field.id}`] && (
                                                                    <p className='validate-error'>{inputFieldErrorMessage[`${section.id}-${field.id}`]}</p>
                                                                )}
                                                            </>
                                                        ) : field?.id === 6 ? (
                                                            <>
                                                                <Select
                                                                    className='menu_open_select'
                                                                    classNamePrefix='menu_open_select_class'
                                                                    value={field?.value ? { value: field?.value, label: districtsBySection[section.id]?.find(item => item.id === field?.value)?.name } : null}
                                                                    aria-label="Default select example"
                                                                    onChange={(e) => handleDropdownChangeFirst(e, "district_id", field?.id, section?.id)}
                                                                    onMenuOpen={() => {
                                                                        setClickDropdown({ ...clickDropdown, district_id: true })

                                                                        if (!valueStore[`${section?.id}-${field?.id}`]) {
                                                                            setInputFieldErrorMessage((prevErrors) => ({
                                                                                ...prevErrors,
                                                                                [`${section.id}-${field?.id}`]: "Please select district"
                                                                            }));

                                                                        }
                                                                        if (!valueStore[`${section?.id}-${field?.id}`]) {
                                                                            setInputFieldErrorMessage((prevErrors) => ({
                                                                                ...prevErrors,
                                                                                [`${section.id}-${field?.id}`]: "Please select state"
                                                                            }));

                                                                        }

                                                                    }
                                                                    }
                                                                    options={districtsBySection[section.id]?.map((district) => ({ label: district.name, value: district.id }))}
                                                                    placeholder="Select District"
                                                                />
                                                                {inputFieldErrorMessage[`${section.id}-${field.id}`] && (
                                                                    <p className='validate-error'>{inputFieldErrorMessage[`${section.id}-${field.id}`]}</p>
                                                                )}
                                                            </>
                                                        ) : field?.id === 7 ? (
                                                            <>
                                                                <Select
                                                                    className='menu_open_select'
                                                                    classNamePrefix='menu_open_select_class'
                                                                    value={field?.value ? { value: field?.value, label: citiesBySection[section.id]?.find(item => item.id === field?.value)?.name } : null}

                                                                    aria-label="Default select example"
                                                                    onMenuOpen={() => {
                                                                        setClickDropdown({ ...clickDropdown, city_id: true })

                                                                        if (!valueStore[`${section?.id}-${field?.id}`]) {
                                                                            setInputFieldErrorMessage((prevErrors) => ({
                                                                                ...prevErrors,
                                                                                [`${section.id}-${field?.id}`]: "Please select city"
                                                                            }));

                                                                        }
                                                                        if (!valueStore[`${section?.id}-${field?.id}`]) {
                                                                            setInputFieldErrorMessage((prevErrors) => ({
                                                                                ...prevErrors,
                                                                                [`${section.id}-${field?.id}`]: "Please select district"
                                                                            }));

                                                                        }

                                                                    }
                                                                    }
                                                                    onChange={(e) => handleDropdownChangeFirst(e, "city_id", field?.id, section?.id)}
                                                                    options={citiesBySection[section.id]?.map((city) => ({ label: city.name, value: city.id }))}

                                                                    placeholder="Select City"
                                                                />
                                                                {inputFieldErrorMessage[`${section.id}-${field.id}`] && (
                                                                    <p className='validate-error'>{inputFieldErrorMessage[`${section.id}-${field.id}`]}</p>
                                                                )}
                                                            </>
                                                        ) : null
                                                        )
                                                    }
                                                    {field.type === 'file' && (
                                                        <>
                                                            <input type="file"
                                                                name={field.input_field_name}
                                                                id="profileimg"
                                                                className="w-100 suf-input-field-image"


                                                            />
                                                            <label htmlFor="profileimg" className="suf-input-upload-image"
                                                            >
                                                                <h5 className="para-parts-upload-image">
                                                                    Choose Profile Photo
                                                                </h5>
                                                            </label>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </>
                            ))}
                        {dummyDAta?.map(item => (
                            <>
                                <div className="aod-head">{item?.name_en}</div>
                                <div className="row py-4">
                                    {item.category_form_feilds?.map(field => {

                                        return (
                                            <div className="col-md-4" key={field.id}>
                                                <div className='suf-input-box aod-form-input mb-2'>
                                                    <label className='mb-1 w-100'>{field.name_en}</label>
                                                    {field.type === 'text' && (
                                                        <>
                                                            <input
                                                                className='w-100'
                                                                // onChange={(e) => handleFieldChange(e, item?.id)}
                                                                value={field?.value || " "}
                                                                onKeyUp={() => handleKeyUp(field, item)}

                                                                onChange={(e) => {
                                                                    if (field.id === 14) {

                                                                        handleEmailChange(e, field?.id, item?.id);

                                                                    }
                                                                    else if (field.id === 15) {
                                                                        handleMobileNumberChange(e, field?.id, item?.id);
                                                                    }

                                                                    else if (field.id === 20) {
                                                                        handleHouseNumberChange(e, field?.id, item?.id);
                                                                    }

                                                                    else {
                                                                        handleFieldChange(e, field?.id, item?.id);
                                                                    }
                                                                }}


                                                                placeholder={`Enter ${field.name_en}`}
                                                                type="text"
                                                                name={field.input_field_name}

                                                            />
                                                            {inputFieldErrorMessage[`${item.id}-${field.id}`] && (
                                                                <p className='validate-error'>{inputFieldErrorMessage[`${item.id}-${field.id}`]}</p>
                                                            )}

                                                        </>
                                                    )}
                                                    {field.type === 'dropdown' &&
                                                        (field?.id === 17 ? (
                                                            <>
                                                                <Select
                                                                    className='menu_open_select'
                                                                    classNamePrefix='menu_open_select_class'

                                                                    value={field?.value ? { value: field?.value, label: statesData?.find(item => item.id === field?.value)?.name } : null}
                                                                    aria-label="Default select example"
                                                                    onChange={(e) => handleDropdownChangeFirst(e, "partner_state_id", field?.id, item?.id)}
                                                                    options={statesData?.map((state) => ({ label: state.name, value: state.id }))}
                                                                    placeholder="Select State"
                                                                    onMenuOpen={() => {
                                                                        setClickDropdown({ ...clickDropdown, partner_state_id: true })


                                                                        if (!valueStore[`${item?.id}-${field?.id}`]) {
                                                                            setInputFieldErrorMessage((prevErrors) => ({
                                                                                ...prevErrors,
                                                                                [`${item.id}-${field?.id}`]: "Please select state"
                                                                            }));

                                                                        }
                                                                    }
                                                                    }
                                                                />
                                                                {inputFieldErrorMessage[`${item.id}-${field.id}`] && (
                                                                    <p className='validate-error'>{inputFieldErrorMessage[`${item.id}-${field.id}`]}</p>
                                                                )}
                                                            </>
                                                        ) : field?.id === 18 ? (
                                                            <>
                                                                <Select
                                                                    className='menu_open_select'
                                                                    classNamePrefix='menu_open_select_class'
                                                                    value={field?.value ? { value: field?.value, label: districtsBySection[item.id]?.find(item => item.id === field?.value)?.name } : null}
                                                                    aria-label="Default select example"
                                                                    onChange={(e) => handleDropdownChangeFirst(e, "partner_district_id", field?.id, item?.id)}
                                                                    options={districtsBySection[item.id]?.map((district) => ({ label: district.name, value: district.id }))}
                                                                    placeholder="Select District"
                                                                    onMenuOpen={() => {
                                                                        setClickDropdown({ ...clickDropdown, partner_state_id: true })


                                                                        if (!valueStore[`${item?.id}-${field?.id}`]) {
                                                                            setInputFieldErrorMessage((prevErrors) => ({
                                                                                ...prevErrors,
                                                                                [`${item.id}-${field?.id}`]: "Please select district"
                                                                            }));

                                                                        }
                                                                        if (!valueStore[`${item?.id}-${field?.id}`]) {
                                                                            setInputFieldErrorMessage((prevErrors) => ({
                                                                                ...prevErrors,
                                                                                [`${item.id}-${field?.id}`]: "Please select state"
                                                                            }));

                                                                        }
                                                                    }
                                                                    }
                                                                />
                                                                {inputFieldErrorMessage[`${item.id}-${field.id}`] && (
                                                                    <p className='validate-error'>{inputFieldErrorMessage[`${item.id}-${field.id}`]}</p>
                                                                )}
                                                            </>
                                                        ) : field?.id === 19 ? (
                                                            <>
                                                                <Select
                                                                    className='menu_open_select'
                                                                    classNamePrefix='menu_open_select_class'
                                                                    value={field?.value ? { value: field?.value, label: citiesBySection[item.id]?.find(item => item.id === field?.value)?.name } : null}
                                                                    aria-label="Default select example"
                                                                    onChange={(e) => handleDropdownChangeFirst(e, "partner_city_id", field?.id, item?.id)}
                                                                    placeholder="Select City"
                                                                    options={citiesBySection[item.id]?.map((city) => ({ label: city.name, value: city.id }))}
                                                                    onMenuOpen={() => {
                                                                        setClickDropdown({ ...clickDropdown, partner_state_id: true })


                                                                        if (!valueStore[`${item?.id}-${field?.id}`]) {
                                                                            setInputFieldErrorMessage((prevErrors) => ({
                                                                                ...prevErrors,
                                                                                [`${item.id}-${field?.id}`]: "Please select city"
                                                                            }));

                                                                        }
                                                                        if (!valueStore[`${item?.id}-${field?.id}`]) {
                                                                            setInputFieldErrorMessage((prevErrors) => ({
                                                                                ...prevErrors,
                                                                                [`${item.id}-${field?.id}`]: "Please select district"
                                                                            }));

                                                                        }
                                                                    }
                                                                    }

                                                                />
                                                                {inputFieldErrorMessage[`${item.id}-${field.id}`] && (
                                                                    <p className='validate-error'>{inputFieldErrorMessage[`${item.id}-${field.id}`]}</p>
                                                                )}
                                                            </>
                                                        ) : null
                                                        )
                                                    }
                                                    {field.id === 22 && (

                                                        <>

                                                            <input type="file"
                                                                name={field.input_field_name}
                                                                id="profileimg"
                                                                className="w-100 suf-input-field-image"
                                                                onChange={(e) => handleImageUpload(e, field?.id, item?.id)}


                                                            />

                                                            <label htmlFor="profileimg" className="od-input-upload-image"
                                                                onClick={() => setImageData(item?.id)}
                                                            >
                                                                <div className='od-image-upload-div'>
                                                                    <UploadIconPhoto />
                                                                </div>
                                                                <h5 className="para-parts-upload-image">
                                                                    Choose Photo
                                                                </h5>
                                                            </label>
                                                            {inputFieldErrorMessage[`${item.id}-${field.id}`] && (
                                                                <p className='validate-error'>{inputFieldErrorMessage[`${item.id}-${field.id}`]}</p>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        )
                                    }


                                    )}
                                </div>
                            </>
                        ))}
                        <div className='od-butt'>
                            <button className='od-das-butt'>Dashboard</button>
                            <button className='od-Nex-butt' onClick={handleSubmit}>
                                Next
                            </button>
                        </div>

                    </div> */}

                </div>

            </div>
        </Dashboard>
  )
}

export default EmployeeDetails
