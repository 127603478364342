import React from 'react';
import "./GuidelinesComponent.css";
import { CheckIcon, CircleIcon, MandatoryDocumentIcon } from './GuidelineIcon';
import { useNavigate } from 'react-router-dom';
import { GetLocalStorage } from '../../Utils/Utils';
const GuidelinesComponent = () => {
    const getLoginSubCabData = GetLocalStorage("LoginSubCatData");

    const navigate = useNavigate();
    const hanldeNavigate = () => {
        if (getLoginSubCabData) {
            navigate("/application/organization-details");

        }
    }
    return (
        <div className="gc-outline">
            <div className="gc-apply-butt">
                <button disabled onClick={() => hanldeNavigate()}>Apply Now</button>
            </div>
            <div className="gc-inner">
                <div className="gc-col">
                    <div className="gc-head">
                        <MandatoryDocumentIcon /> Mandatory Documents
                    </div>
                    <div className='gc-content'>
                        <p><CheckIcon />Lorem ipsum dolor sit amet, consectetur adipiscing ept, sed do eiusmod tempor incididunt ut labore et dolore  magna apqua. Ut enim ad</p>
                        <p><CheckIcon />Lorem ipsum dolor sit amet, consectetur adipiscing ept, sed do eiusmod tempor incididunt ut labore et dolore </p>
                        <p><CheckIcon />Lorem ipsum dolor sit amet, consectetur adipiscing ept, sed do eiusmod tempor </p>
                        <p><CheckIcon />Lorem ipsum dolor sit amet, consectetur adipiscing ept, sed do eiusmod tempor incididunt ut labore et dolore  magna apqua. </p>
                        <p><CheckIcon />Lorem ipsum dolor sit amet, consectetur adipiscing ept, sed do eiusmod tempor </p>
                        <p><CheckIcon />Lorem ipsum dolor sit amet, consectetur adipiscing ept, sed do eiusmod tempor incididunt ut labore et dolore </p>
                        <p><CheckIcon />Lorem ipsum dolor sit amet, consectetur adipiscing ept, sed do eiusmod tempor </p>
                        <p><CheckIcon />Lorem ipsum dolor sit amet, consectetur adipiscing ept, sed do eiusmod tempor incididunt ut labore et dolore  magna apqua. Ut enim ad</p>
                        <p><CheckIcon />Lorem ipsum dolor sit amet, consectetur adipiscing ept, sed do eiusmod tempor incididunt ut labore et dolore </p>
                        <p><CheckIcon />Lorem ipsum dolor sit amet, consectetur adipiscing ept, sed do eiusmod tempor </p>
                        <p><CheckIcon />Lorem ipsum dolor sit amet, consectetur adipiscing ept, sed do eiusmod tempor incididunt ut labore et dolore  magna apqua. </p>
                        <p><CheckIcon />Lorem ipsum dolor sit amet, consectetur adipiscing ept, sed do eiusmod tempor </p>
                        <p><CheckIcon />Lorem ipsum dolor sit amet, consectetur adipiscing ept, sed do eiusmod tempor incididunt ut labore et dolore </p>
                        <p><CheckIcon />Lorem ipsum dolor sit amet, consectetur adipiscing ept, sed do eiusmod tempor </p>
                    </div>
                </div>
                <div className="gc-col">
                    <div className="gc-head">
                        <MandatoryDocumentIcon /> Optional Documents
                    </div>
                    <div className='gc-content-bottom'>
                        <p><CircleIcon />Lorem ipsum dolor sit amet, consectetur adipiscing ept, sed do eiusmod tempor incididunt ut labore et dolore  magna apqua. Ut enim ad</p>
                        <p><CircleIcon />Lorem ipsum dolor sit amet, consectetur adipiscing ept, sed do eiusmod tempor incididunt ut labore et dolore </p>
                        <p><CircleIcon />Lorem ipsum dolor sit amet, consectetur adipiscing ept, sed do eiusmod tempor </p>
                        <p><CircleIcon />Lorem ipsum dolor sit amet, consectetur adipiscing ept, sed do eiusmod tempor incididunt ut labore et dolore  magna apqua. </p>
                        <p><CircleIcon />Lorem ipsum dolor sit amet, consectetur adipiscing ept, sed do eiusmod tempor </p>
                        <p><CircleIcon />Lorem ipsum dolor sit amet, consectetur adipiscing ept, sed do eiusmod tempor incididunt ut labore et dolore </p>
                        <p><CircleIcon />Lorem ipsum dolor sit amet, consectetur adipiscing ept, sed do eiusmod tempor </p>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default GuidelinesComponent
