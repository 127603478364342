import React from 'react'
import registration from "../../../assets/images/registration.png"
import { useLanguage } from '../../LanguageProvider/LanguageProvider '
const HomeStepByStep = () => {
  const {language,setLanguage}=useLanguage()
  return (
    <section>
      <div className='hsbs-mountain'></div>
      <div className='hsbs-main'>
      <div className="container">
        <div className="text-center pt-4 hcat-main">
        <p className='hex-p mb-2'>{language ? "चरण दर चरण रेजिस्ट्रेशन":"STEP BY STEP REGISTRATION"}</p>
        <div className='hsbs-head'>{language ? "रेजिस्ट्रेशन की प्रक्रिया":"Registration Process"}</div>
        <main>
    <ul class="infoGraphic">
      <li>
        <div class="numberWrap">
          <div class="number fontColor1">1</div>
          <div class="coverWrap">
            <div class="numberCover"></div>
          </div>
        </div>
        <div class="content">
          <div class="icon iconCodepen"></div>
          <h2 class="TxtBlue">{language ?"रेजिस्ट्रेशन/साइन अप करें":"Registration/ Sign up"}</h2>
          <p>{language ? "एक खाता बनाएं, अपना ईमेल सत्यापित करें और अपनी प्रोफ़ाइल पूरी करें":"Create an account, verify your email, and complete your profile"}</p>
        </div>
      </li>
      <li>
        <div class="numberWrap">
          <div class="number fontColor2">2</div>
          <div class="coverWrap">
            <div class="numberCover"></div>
          </div>
        </div>
        <div class="content">
          <div class="icon iconSocial"></div>
          <h2 class="TxtGreen">{language ? "श्रेणी का चयन करें":"Choose a Category"}</h2>
          <p>{language ? "ट्रैवल ट्रेड व्यवसाय का प्रकार (जैसे होटल) और कोई उप-श्रेणियाँ चुनें":"Choose the type of travel trade business (e.g. hotel) and any sub-categories"}</p>
        </div>
      </li>
      <li>
        <div class="numberWrap">
          <div class="number  fontColor3">3</div>
          <div class="coverWrap">
            <div class="numberCover"></div>
          </div>
        </div>
        <div class="content">
          <div class="icon iconAirplane"></div>
          <h2 class="TxtPurple">{language ? "अपना विवरण भरें":"Fill your details"}</h2>
          <p>{language ? "आवास, सुविधाओं, अनुपालन के बारे में विवरण दर्ज करें और आवश्यक दस्तावेज अपलोड करें।":"Enter details about the accommodation, facilities, compliance, and upload necessary documents."}</p>
        </div>
      </li>
      <li>
        <div class="numberWrap">
          <div class="number  fontColor4">4</div>
          <div class="coverWrap">
            <div class="numberCover"></div>
          </div>
        </div>
        <div class="content">
          <div class="icon iconMap"></div>
          <h2 class="TxtGold">{language ? "भुगतान":"Payment"}</h2>
          <p>{language ? "उपलब्ध भुगतान विधियों के माध्यम से आवश्यक शुल्क का भुगतान करें।":"Pay the required fees through the available payment methods."}</p>
        </div>
      </li>
      <li>
        <div class="numberWrap">
          <div class="number  fontColor3">5</div>
          <div class="coverWrap">
            <div class="numberCover"></div>
          </div>
        </div>
        <div class="content">
          <div class="icon iconAirplane"></div>
          <h2 class="TxtPurple">{language ? "सत्यापन एवं अनुमोदन":"Verification and Approval"}</h2>
          <p>{language ? "अधिकारी जानकारी को सत्यापित करते हैं और साइट निरीक्षण कर सकते हैं। अनुमोदन या अस्वीकरण अनुपालन के आधार पर होता है।":"Authorities verify the information and may conduct a site inspection. Approval or denial follows based on compliance."}</p>
        </div>
      </li>
      <li>
        <div class="numberWrap">
          <div class="number  fontColor2">6</div>
          <div class="coverWrap">
            <div class="numberCover"></div>
          </div>
        </div>
        <div class="content">
          <div class="icon iconSocial"></div>
          <h2 class="TxtGreen">{language ? "प्रमाणपत्र/लाइसेंस जारी करना":"Issuance of Certificate/License"}</h2>
          <p>{language ? "एक बार स्वीकृत हो जाने पर, अपने व्यवसाय को कानूनी रूप से संचालित करने के लिए प्रमाणपत्र/लाइसेंस प्राप्त करें।":"Once approved, receive the certificate/license to legally operate your business."}</p>
        </div>
      </li>
    </ul>
  </main>
        </div>
      </div>
      </div>
    </section>
  )
}
export default HomeStepByStep