import React, { useState } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import "./Header.css";
import { BsList, BsXLg } from 'react-icons/bs';
import { useLanguage } from '../LanguageProvider/LanguageProvider ';
const HeaderBottom = ({ homePageContent }) => {
    const { language, setLanguage } = useLanguage()
    const [open, setOpen] = useState(false)
    return (
        <div className='hd-cont hd-bg'>
            <Navbar className="bg-body hd-main container p-0">
                <div className='m-0 w-100 d-flex hd-res-cont'>
                    <NavLink to="/" className="navbar-brand"><img className="hd-logo" src={'https://utdbstg.dilonline.in/assets/images/admin/website_logo_1718344813.png'} /></NavLink>
                    <div className='hd-toggle'>{
                        open ? <BsXLg onClick={() => setOpen(false)} /> :
                            <BsList onClick={() => setOpen(true)} />}
                    </div>
                    <div id="basic-navbar-nav" className={`${open ? "open-toggle" : "close-toggle"} justify-content-end pe-5`}>
                        <Nav className="hd-links">
                            {
                                homePageContent?.header_menu?.map((item) => (
                                    <NavLink
                                        key={item.id}
                                        to={item.id === 1 ? "/" : "#"}
                                        className={item.id !== 1 ? "disabled-link" : ""}
                                        onClick={item.id !== 1 ? (e) => e.preventDefault() : null}
                                    >
                                        {language ? item?.name_hi : item?.name}
                                    </NavLink>
                                ))
                            }

                        </Nav>
                    </div>
                </div>
            </Navbar>
        </div>
    )
}
export default HeaderBottom