import React, { useEffect, useRef, useState } from 'react'
import { BsEye } from "react-icons/bs";
import { BsEyeSlash } from "react-icons/bs";
import "./LoginForm.css";
import { Link, useNavigate } from 'react-router-dom';
import { AiOutlineUser } from "react-icons/ai";
import { BiLock } from "react-icons/bi";
import leftImg from "../../assets/images/bg-down.png"
import rightImg from "../../assets/images/bg-up.png"
import { Apiurl, GetLocalStorageParsed, ImageurlIcons, SetLocalStorage, SetLocalStorageStringify, validateonChangeHandle, validateonKeyUpHandle } from '../../Utils/Utils';
import axios from 'axios';
import { useLanguage } from '../LanguageProvider/LanguageProvider ';
const LoginForm = ({ loginFormData, buttData, formData, setLoginFormData }) => {
    const { language, setLanguage } = useLanguage();
    const [errorMessage, setErrorMessage] = useState();
    const [loginFailedAttempt, setLoginFailedAttempt] = useState();
    const [emptyClickMessage, setEmptyClickMessage] = useState();
    const getWithoutLoginSubCatClick = GetLocalStorageParsed("subCategoryClick")
    //states
    const navigate = useNavigate()
    const [loginDetail, setLoginDetail] = useState({
        mobile_number: "",
        password: ""
    })
    const [loginError, setLoginError] = useState({
        mobile_number: "",
        password: ""
    })
    const [passwordVisible, setPasswordVisile] = useState(false);
    const handleClearCache=()=>{
        const option = {
            method: "GET",
            headers: {
              "access-control-allow-origin": "*",
              "content-type": "application/json",
            },
            url: `${Apiurl()}/clear-cache`,
          };
          axios(option)
            .then((e) => {
            
            })
            .catch((error) => {
            });
    }
    //login function
    const handleSubmitLogin = async () => {
        const clear= await handleClearCache();
        const data = await loginFormData?.find(item => item?.name_en.toLowerCase().includes("mobile"));
        if (data) {
            const exists = await fetchMobileExistence(data?.value);
            if (exists.status == 400) {
                setLoginFormData(prevData =>
                    prevData.map(i =>
                        i.id === data.id ? { ...i, error: { hi: "मोबाइल नंबर हमारे रिकॉर्ड में नहीं मिला।", en: "Mobile Number not found in our records." } } : i
                    )
                );
            } else {
                setLoginFormData(prevData =>
                    prevData.map(i =>
                        i.id === data.id ? { ...i, error: "" } : i
                    )
                );
                try {
                    const formData = new FormData();
                    loginFormData?.forEach(i => {
                        if (i?.value) {
                            formData.append([i.input_field_name], i?.value);
                        }
                    });
                    // formData.append("password", loginDetail.password);
                    const response = await axios.post(`${Apiurl()}/login`, formData);
                    if (response?.data?.status === 200 && response?.data?.data?.failed_login_attempts || response?.data?.status === 400) {
                        setErrorMessage(response?.data?.messages)
                        setLoginFailedAttempt(response?.data?.data)
                        return;
                    } else {
                        SetLocalStorage("userInfo", response?.data?.data?.token)
                        SetLocalStorageStringify("userDetail", response?.data?.data?.user)
                        SetLocalStorageStringify("tokenExpiry", response?.data?.data?.expire_at)
                        if (response?.data?.data?.user?.roles === 'superadmin') {
                            navigate("/admin")
                        } else {
                            // if (getWithoutLoginSubCatClick) {
                            //     navigate("/application/organization-details")
                            // } else {
                                navigate("/category")
                            // }
                        }
                    }
                } catch (error) {
                }
            }
        }
    }
    const fetchMobileExistence = async (i) => {
        try {
            const formData = new FormData();
            formData.append("mobile_number", i);
            const reponse = await axios.post(`${Apiurl()}/is-email-mobileno-exists`, formData);
            return reponse?.data
        } catch (error) {
        }
    }
    useEffect(() => {
        if (errorMessage || language) {
            setEmptyClickMessage({ hi: errorMessage?.hindi, en: errorMessage?.english })
        }
    }, [errorMessage, language])
    const allFieldsValid = () => {
        return loginFormData?.every(item => item.value && item.error === "");
    };
    const hadleShowMessgae = () => {
        if(loginFormData?.every(item => item.value)){

            setEmptyClickMessage({ hi: "कृपया सभी फ़ील्ड सत्यापित करें", en: "Please validate all fields" })
        }else{
            setEmptyClickMessage({ hi: "कृपया सभी विवरण भरें", en: "Please fill all details" })

        }
        
    }
    return (
        <section className='p-5 main-outer-container'>
            <img src={leftImg} alt="background-image" className='main-bg-imgleft' />
            <img src={rightImg} alt="background-image" className='main-bg-imgright' />
            <div className='container'>
                <div className='row lf-row d-flex justify-content-center align-items-center'>
                    <div className="col-md-8 lf-col p-0">
                        <div className="lf-outer-con">
                            {formData?.map((i) => (<h2 className='lf-heading'>{language ? i?.name_hi : i?.name_en}</h2>))}
                            <div className="lf-inner">
                                <div className="lf-outer">
                                    {/* mobile_number */}
                                    {loginFormData?.map((item) => {
                                        return (
                                            <div className='lf-input-box mb-4' key={item.id} onClick={() => {setEmptyClickMessage(""); setLoginFailedAttempt();}}>
                                                <div className="used-icon-password-visible" key={item.id}>
                                                    <img className='lg-input-icon' src={`${ImageurlIcons()}/login-form-icon//${item?.icon}`} alt="" />
                                                    <input
                                                        className='w-100'
                                                        placeholder={language ? item?.placeholder_hi : item?.placeholder_en}
                                                        type={item?.type == "password" ? `${passwordVisible ? 'text' : "password"}` : item?.type}
                                                        name={item?.input_field_name}
                                                        value={item?.value}
                                                        onChange={(e) => {
                                                            const updatedFields = loginFormData.map(field => {
                                                                if (field.id === item?.id) {
                                                                    return {
                                                                        ...field,
                                                                        value: e?.target?.value
                                                                    };
                                                                }
                                                                return field;
                                                            });
                                                            setLoginFormData(updatedFields);
                                                            validateonChangeHandle(item, e, loginFormData, setLoginFormData); setLoginFailedAttempt("")
                                                        }}
                                                        onKeyUp={(e) => { validateonKeyUpHandle(item, e.target.value, loginFormData, setLoginFormData) }}
                                                    />
                                                    {
                                                        item?.type == "password" ? <>
                                                            {
                                                                passwordVisible ?
                                                                    <BsEye className="icon-password-visible" onClick={() => setPasswordVisile(!passwordVisible)} /> :
                                                                    <BsEyeSlash className="icon-password-visible" onClick={() => setPasswordVisile(!passwordVisible)} />
                                                            }
                                                        </> : null
                                                    }
                                                    {item?.error?.en && <p className='validate-error'>{language ? item?.error?.hi : item?.error?.en}</p>}
                                                </div>
                                            </div>
                                        );
                                    })}
                                    <p className='validate-error text-center mb-2'>
                                        {language ? emptyClickMessage?.hi : emptyClickMessage?.en}
                                    </p>
                                    {loginFailedAttempt?.block_time && <p className='validate-error'>{language ? `आप ${loginFailedAttempt?.block_time} के बाद दोबारा लॉगइन कर सकते हैं` : `You can again login after ${loginFailedAttempt?.block_time}`}</p>}                                    {/* Remember me */}
                                    {buttData?.map((butt) => {
                                        return (
                                            <>
                                                {butt?.id === 4 ?
                                                    <>
                                                        <div className='lf-remember-box'>
                                                            <Link to="/forgot-password">{language ? butt?.name_hi : butt?.name_en}</Link>
                                                        </div>
                                                    </>
                                                    :
                                                    butt?.id === 3 ?
                                                        <>
                                                            {
                                                                !allFieldsValid() ? <div className="lf-btn">
                                                                    <button onClick={hadleShowMessgae}>{language ? butt?.name_hi : butt?.name_en}</button>
                                                                </div> : <div className="lf-btn">
                                                                    <button onClick={handleSubmitLogin}>{language ? butt?.name_hi : butt?.name_en}</button>
                                                                </div>
                                                            }
                                                        </>
                                                        :
                                                        butt?.id === 5 ?
                                                            <div className='lf-last'>
                                                                <p><Link to="/signup">{language ? butt?.name_hi : butt?.name_en}</Link></p>
                                                            </div>
                                                            : null
                                                }
                                            </>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}
export default LoginForm 