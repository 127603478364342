import React, { useEffect, useState } from 'react'
import ResetPasswordForm from '../../components/ResetPassword/ResetPasswordForm'
import Banner from '../../components/Banner/Banner'
import MainLayout from '../../components/MainLayout/MainLayout'
import axios from 'axios'
import { Apiurl } from '../../Utils/Utils'
import { useLanguage } from '../../components/LanguageProvider/LanguageProvider '

const ResetPassword = () => {
  const {language,setLanguage}=useLanguage();

  
  const [resetPasswordFormData, setResetPasswordFormData] = useState([]);
  const [resetPasswordAllData, setResetPasswordAllData] = useState();
  //  Get reset forms fields
  const GetResetFormField = () => {
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
      },
      url: `${Apiurl()}/reset-password-form`,
    };
    axios(option)
      .then((e) => {
        const filterOnlyRequiredFields = e?.data?.data?.input_fields?.filter((i) => i?.required !== "0")
        const updatedData = filterOnlyRequiredFields?.map(item => ({
            ...item,
            value: '',
            error: ''  // Set the default value as needed
        }));
        setResetPasswordFormData(updatedData)
        setResetPasswordAllData(e?.data?.data)


      })
      .catch((error) => {

      });
  };
useEffect(()=>{
  window.scrollTo(0, 0);
  GetResetFormField()
},[])
  return (
    <>
      <MainLayout>
        <Banner first={language ? "होम": "Home"} second={language ? "पासवर्ड रीसेट पृष्ठ":"Reset Password Page"} head={language ? "पासवर्ड रीसेट गए":"Reset Password"}  />
        <ResetPasswordForm resetPasswordFormData={resetPasswordFormData} setResetPasswordFormData={setResetPasswordFormData} resetPasswordAllData={resetPasswordAllData}/>
      </MainLayout>
    </>
  )
}

export default ResetPassword
