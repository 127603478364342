import './App.css';
import Home from './pages/Home/Home';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './pages/Login/Login';
import SignUp from './pages/SignUp/SignUp';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import Dashboard from './pages/Dashboard/Dashboard';
import HeaderTop from './components/Header/HeaderTop';
import HeaderBottom from './components/Header/HeaderBottom';
import Footer from './components/Footer/Footer';
import Guidelines from './pages/Guidelines/Guidelines';
import Application from './pages/Application/Application';
import DashboardCategory from './pages/DashboardCategory/DashboardCategory';
import { useEffect, useState } from 'react';
import { Apiurl } from './Utils/Utils';
import axios from 'axios';
import CategoryInfo from './pages/CategoryInfo/CategoryInfo';
import Category from './components/Admin/Category/Category';
import SubCategory from './components/Admin/SubCategory/SubCategory';
import DashboardLayout from './components/Admin/DashboardLayout/DashboardLayout';
import RegistrationForm from './components/Admin/RegistrationForm/RegistrationForm';
import ApplicationTabs from './components/Admin/ApplicationTabs/ApplicationTabs';
import ApplicationSections from './components/Admin/ApplicationSections/ApplicationSections';
import FormFields from './components/Admin/FormFields/FormFields';
import Users from './components/Admin/Users/Users';
import { LanguageProvider } from './components/LanguageProvider/LanguageProvider ';
import AdminLogin from './components/Admin/AdminLogin/AdminLogin';
import AdminResetPassword from './components/Admin/AdminResetPassword/AdminResetPassword';
import AdminForgotPassword from './components/Admin/AdminForgotPassword/AdminForgotPassword';
import RegistrationFormAdd from './components/Admin/RegistrationForm/RegistrationFormAdd';
import AdminLoginAdd from './components/Admin/AdminLogin/AdminLoginAdd';
import AdminResetPasswordAdd from './components/Admin/AdminResetPassword/AdminResetPasswordAdd';
import AdminForgotPasswordAdd from './components/Admin/AdminForgotPassword/AdminForgotPasswordAdd';
function App() {
  const ClearCache = () => {
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
      },
      url: `${Apiurl()}/clear-cache`,
    };
    axios(option)
      .then((e) => {})
      .catch((error) => {});
  };
  useEffect(() => {
    ClearCache()
  }, [])
 
  return (
    <LanguageProvider>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/categoryInfo' element={<CategoryInfo />} />
        <Route path='/login' element={<Login />} />
        <Route path='/signup' element={<SignUp />} />
        <Route path='/reset-password' element={<ResetPassword />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/guidelines' element={<Guidelines />} />
        <Route path='/category' element={<DashboardCategory />} />
        <Route path='/application/*' element={<Application />} />
         {/* Admin Routes */}
         <Route path='/admin/category' element={<Category />} />
         <Route path='/admin/sub-category' element={<SubCategory/>} />
         <Route path='/admin' element={<DashboardLayout/>} />
         <Route path='/admin/registration' element={<RegistrationForm/>} />
         <Route path='/admin/registration/:name' element={<RegistrationFormAdd/>} />
         <Route path='/admin/application-tabs' element={<ApplicationTabs/>} />
         <Route path='/admin/application-sections' element={<ApplicationSections/>} />
         <Route path='/admin/application-form' element={<FormFields/>} />
         <Route path='/admin/users' element={<Users/>} />
         <Route path='/admin/login' element={<AdminLogin/>} />
         <Route path='/admin/login/:name' element={<AdminLoginAdd/>} />
         <Route path='/admin/reset-password' element={<AdminResetPassword/>} />
         <Route path='/admin/reset-password/:name' element={<AdminResetPasswordAdd/>} />
         <Route path='/admin/forgot-password' element={<AdminForgotPassword/>} />
         <Route path='/admin/forgot-password/:name' element={<AdminForgotPasswordAdd/>} />
      </Routes>
    </BrowserRouter>
    </LanguageProvider>
  );
}
export default App;