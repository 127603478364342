import React, { useEffect } from 'react'
import SignUpForm from '../../components/SignUp/SignUpForm'
import Banner from '../../components/Banner/Banner'
import { useLocation } from 'react-router-dom'
import MainLayout from '../../components/MainLayout/MainLayout'
import { useLanguage } from '../../components/LanguageProvider/LanguageProvider '

const SignUp = () => {
  const {language,setLanguage}=useLanguage();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <MainLayout>
      <Banner first={language ? "होम": "Home"} second={language ? "रेजिस्ट्रेशन पृष्ठ":"Registration Page"} head={language ? "रेजिस्ट्रेशन ":"Registration "} />
      <SignUpForm />
      </MainLayout>
    </>
  )
}
export default SignUp