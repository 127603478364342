import React, { useEffect } from 'react'
import MainLayout from '../../components/MainLayout/MainLayout'
import Banner from '../../components/Banner/Banner'
import CategoryInfoDetail from '../../components/CategoryInfo/CategoryInfoDetail'
import { useLanguage } from '../../components/LanguageProvider/LanguageProvider '

const CategoryInfo = () => {
    const {language}=useLanguage();
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <MainLayout>
            <Banner first={language ? "होम": "Home"} second={language ? "श्रेणी जानकारी":"Category Info"} head={language ? "श्रेणी जानकारी":"Category Info "} />
            <CategoryInfoDetail/>
        </MainLayout>
    )
}

export default CategoryInfo
