import React from 'react'
import { CheckIcon, CircleIcon, MandatoryDocumentIcon } from '../Guidelines/GuidelineIcon'
import { ApplicationFees, CertificateTenure, Timeline } from './CategoryIcons'
import './CategoryInfo.css'
import { useNavigate } from 'react-router-dom'
import { useLanguage } from '../LanguageProvider/LanguageProvider '
const CategoryInfoDetail = () => {
    const {language}=useLanguage();

    const navigate=useNavigate();
    return (
        <div className="container">
            <div className="gc-outline">
                <div className=' mb-5 d-flex gap-3 w-100 gc-main-div'>
                    <div className=" cid-main-div">
                        <div className="cid-circle">
                            <ApplicationFees />
                        </div>
                        <div className="cid-text">
                            <p className='app-fee'>{language ? "आवेदन शुल्क":"Application Fees"}</p>
                            <p className='app-rs'>Rs 2000</p>
                        </div>
                    </div>
                    <div className="cid-timeline-div">
                    <div className="cid-circle">
                            <Timeline />
                        </div>
                        <div className="cid-text">
                            <p className='app-fee'>{ language ? "समय" :"Timeline"}</p>
                            <p className='app-rs'>{language ? "10 दिन": "10 Days"}</p>
                        </div>
                    </div>
                    <div className=" cid-certificate-div">
                    <div className="cid-circle">
                            <CertificateTenure />
                        </div>
                        <div className="cid-text">
                            <p className='app-fee'>{language ? "प्रमाणपत्र कार्यकाल": "Certificate Tenure:"}</p>
                            <p className='app-rs'>{language ? "3 साल":"3 Years"}</p>
                        </div>
                    </div>
                    <div className="apply-now-div ">
                    <button className='cid-apply-now' onClick={()=>navigate("/login")}>{language ? "आवेदन करें":" Apply Now"}</button>
                    </div>
                </div>
                <div className="gc-inner">
                    <div className="gc-col">
                        <div className="gc-head">
                            <MandatoryDocumentIcon /> {language ? "अनिवार्य दस्तावेज़" : "Mandatory Documents"}
                        </div>
                        <div className='gc-content'>
                            <p><CheckIcon />{language ? "लोरेम इप्सम डोलोर सिट अमेट, कंसेटेचर एडिपिसिंग ईपीटी, सेड डू ईयूसमॉड टेम्पोर इंसीडिडंट यूटी लेबोर एट डोलोर मैग्ना एपीक्वा। यूटी एनिम विज्ञापन" :"Lorem ipsum dolor sit amet, consectetur adipiscing ept, sed do eiusmod tempor incididunt ut labore et dolore  magna apqua. Ut enim ad"}</p>
                            <p><CheckIcon />{language ? "लोरेम इप्सम डोलर सिट अमेट, कंसेक्टेचर एडिपिसिंग ईपीटी, सेड डू ईयसमॉड टेम्पोर इंसिडिडंट यूटी लेबोर एट डोलोर" :" Lorem ipsum dolor sit amet, consectetur adipiscing ept, sed do eiusmod tempor incididunt ut labore et dolore"} </p>
                            <p><CheckIcon />{language ? "लोरेम इप्सम डोलर सिट अमेट, कंसेक्टेचर एडिपिसिंग ईपीटी, सेड डू ईयसमॉड टेम्पोर":"Lorem ipsum dolor sit amet, consectetur adipiscing ept, sed do eiusmod tempor"} </p>
                            <p><CheckIcon />{language ? "लोरेम इप्सम डोलोर सिट अमेट, कंसेटेचर एडिपिसिंग ईपीटी, सेड डू ईयूसमॉड टेम्पोर इंसीडिडंट यूटी लेबोर एट डोलोर मैग्ना एपीक्वा। यूटी एनिम विज्ञापन" :"Lorem ipsum dolor sit amet, consectetur adipiscing ept, sed do eiusmod tempor incididunt ut labore et dolore  magna apqua. Ut enim ad"}</p>
                            <p><CheckIcon />{language ? "लोरेम इप्सम डोलर सिट अमेट, कंसेक्टेचर एडिपिसिंग ईपीटी, सेड डू ईयसमॉड टेम्पोर इंसिडिडंट यूटी लेबोर एट डोलोर" :" Lorem ipsum dolor sit amet, consectetur adipiscing ept, sed do eiusmod tempor incididunt ut labore et dolore"} </p>
                            <p><CheckIcon />{language ? "लोरेम इप्सम डोलर सिट अमेट, कंसेक्टेचर एडिपिसिंग ईपीटी, सेड डू ईयसमॉड टेम्पोर":"Lorem ipsum dolor sit amet, consectetur adipiscing ept, sed do eiusmod tempor"} </p><p><CheckIcon />{language ? "लोरेम इप्सम डोलोर सिट अमेट, कंसेटेचर एडिपिसिंग ईपीटी, सेड डू ईयूसमॉड टेम्पोर इंसीडिडंट यूटी लेबोर एट डोलोर मैग्ना एपीक्वा। यूटी एनिम विज्ञापन" :"Lorem ipsum dolor sit amet, consectetur adipiscing ept, sed do eiusmod tempor incididunt ut labore et dolore  magna apqua. Ut enim ad"}</p>
                            <p><CheckIcon />{language ? "लोरेम इप्सम डोलर सिट अमेट, कंसेक्टेचर एडिपिसिंग ईपीटी, सेड डू ईयसमॉड टेम्पोर इंसिडिडंट यूटी लेबोर एट डोलोर" :" Lorem ipsum dolor sit amet, consectetur adipiscing ept, sed do eiusmod tempor incididunt ut labore et dolore"} </p>
                            <p><CheckIcon />{language ? "लोरेम इप्सम डोलर सिट अमेट, कंसेक्टेचर एडिपिसिंग ईपीटी, सेड डू ईयसमॉड टेम्पोर":"Lorem ipsum dolor sit amet, consectetur adipiscing ept, sed do eiusmod tempor"} </p><p><CheckIcon />{language ? "लोरेम इप्सम डोलोर सिट अमेट, कंसेटेचर एडिपिसिंग ईपीटी, सेड डू ईयूसमॉड टेम्पोर इंसीडिडंट यूटी लेबोर एट डोलोर मैग्ना एपीक्वा। यूटी एनिम विज्ञापन" :"Lorem ipsum dolor sit amet, consectetur adipiscing ept, sed do eiusmod tempor incididunt ut labore et dolore  magna apqua. Ut enim ad"}</p>
                            <p><CheckIcon />{language ? "लोरेम इप्सम डोलर सिट अमेट, कंसेक्टेचर एडिपिसिंग ईपीटी, सेड डू ईयसमॉड टेम्पोर इंसिडिडंट यूटी लेबोर एट डोलोर" :" Lorem ipsum dolor sit amet, consectetur adipiscing ept, sed do eiusmod tempor incididunt ut labore et dolore"} </p>
                            <p><CheckIcon />{language ? "लोरेम इप्सम डोलर सिट अमेट, कंसेक्टेचर एडिपिसिंग ईपीटी, सेड डू ईयसमॉड टेम्पोर":"Lorem ipsum dolor sit amet, consectetur adipiscing ept, sed do eiusmod tempor"} </p>
                        </div>
                    </div>
                    <div className="gc-col">
                        <div className="gc-head">
                            <MandatoryDocumentIcon />{language ? "वैकल्पिक दस्तावेज़":"Optional Documents"}
                        </div>
                        <div className='gc-content-bottom'>
                            <p><CircleIcon />{language ? "लोरेम इप्सम डोलोर सिट अमेट, कंसेटेचर एडिपिसिंग ईपीटी, सेड डू ईयूसमॉड टेम्पोर इंसीडिडंट यूटी लेबोर एट डोलोर मैग्ना एपीक्वा। यूटी एनिम विज्ञापन" :"Lorem ipsum dolor sit amet, consectetur adipiscing ept, sed do eiusmod tempor incididunt ut labore et dolore  magna apqua. Ut enim ad"}</p>
                            <p><CircleIcon />{language ? "लोरेम इप्सम डोलर सिट अमेट, कंसेक्टेचर एडिपिसिंग ईपीटी, सेड डू ईयसमॉड टेम्पोर इंसिडिडंट यूटी लेबोर एट डोलोर" :" Lorem ipsum dolor sit amet, consectetur adipiscing ept, sed do eiusmod tempor incididunt ut labore et dolore"} </p>
                            <p><CircleIcon />{language ? "लोरेम इप्सम डोलर सिट अमेट, कंसेक्टेचर एडिपिसिंग ईपीटी, सेड डू ईयसमॉड टेम्पोर इंसिडिडंट यूटी लेबोर एट डोलोर" :" Lorem ipsum dolor sit amet, consectetur adipiscing ept, sed do eiusmod tempor incididunt ut labore et dolore"} </p>
                            <p><CircleIcon />{language ? "लोरेम इप्सम डोलर सिट अमेट, कंसेक्टेचर एडिपिसिंग ईपीटी, सेड डू ईयसमॉड टेम्पोर इंसिडिडंट यूटी लेबोर एट डोलोर" :" Lorem ipsum dolor sit amet, consectetur adipiscing ept, sed do eiusmod tempor incididunt ut labore et dolore"} </p>
                        </div>
                    </div>
                </div>
            </div >
        </div>
    )
}
export default CategoryInfoDetail