import React, { useEffect } from 'react'
import GuidelinesComponent from '../../components/Guidelines/GuidelinesComponent'
import Dashboard from '../Dashboard/Dashboard'

const Guidelines = () => {
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])
  return (
    <>
      <Dashboard>
        <GuidelinesComponent />
      </Dashboard>
    </>
  )
}

export default Guidelines
