import React, { useEffect, useState } from 'react'
import { BsCheck, BsEye } from "react-icons/bs";
import { BsEyeSlash } from "react-icons/bs";
import { BiLock } from "react-icons/bi";
import leftImg from "../../assets/images/bg-down.png"
import rightImg from "../../assets/images/bg-up.png"
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Apiurl, ImageurlIcons, validateonChangeHandle, validateonKeyUpHandle } from '../../Utils/Utils';
import { BsX } from "react-icons/bs";
import { useLanguage } from '../LanguageProvider/LanguageProvider ';
const ResetPasswordForm = ({ resetPasswordFormData, setResetPasswordFormData, resetPasswordAllData }) => {
    const { language, setLanguage } = useLanguage();
    const [passwordWrongMessage, setPasswordWrongMessage] = useState();
    const [emptyClickMessage, setEmptyClickMessage] = useState();
    const navigate = useNavigate()
    const location = useLocation();
    const [resetPasswordDetails, setResetPasswordDetails] = useState({
        newPassword: "",
        confirmPassword: ""
    })
    const [passwordVisible, setPasswordVisile] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisile] = useState(false);
    const handleSubmitLogin = async () => {
        try {
            const data = {
            }
            if(location.state.email){
                data.email= location.state.email;

            }else if(location.state.mobile){
                data.mobile_number= location.state.mobile;

            }
            resetPasswordFormData?.forEach((i) => {
                data[i.input_field_name] = i.value;
            });
            // return;
            const reponse = await axios.post(`${Apiurl()}/reset-password`, data);
            if (reponse?.data?.success) {
                navigate("/login")
            }
        } catch (error) {
        }
    }
    useEffect(() => {
        if (resetPasswordFormData?.find(field => field.input_field_name === "password_confirmation")?.value?.length >= 6) {
            const passwordValue = resetPasswordFormData?.find(field => field.input_field_name === "password")?.value;
            const ConfirmpasswordValue = resetPasswordFormData?.find(field => field.input_field_name == "password_confirmation");
            if (passwordValue !== ConfirmpasswordValue?.value) {
                setPasswordWrongMessage({ hi: "पासवर्ड मेल नहीं खाया", en: "Password didn't match" })
            } else {
                setPasswordWrongMessage("");
            }
        } else {
            setPasswordWrongMessage("");
        }
    }, [resetPasswordFormData])
    const allFieldsValid = () => {
        return resetPasswordFormData?.every(item => item.value && item.error === "");
    };
    const hadleShowMessgae = () => {
        if(resetPasswordFormData?.every(item => item.value)){

            setEmptyClickMessage({ hi: "कृपया सभी फ़ील्ड सत्यापित करें", en: "Please validate all fields" })
        }else{
            setEmptyClickMessage({ hi: "कृपया सभी विवरण भरें", en: "Please fill all details" })

        }
    }
    return (
        <section className='p-5 main-outer-container'>
            <img src={leftImg} alt="background-image" className='main-bg-imgleft' />
            <img src={rightImg} alt="background-image" className='main-bg-imgright' />
            <div className='container'>
                <div className='row lf-row d-flex justify-content-center align-items-center'>
                    <div className="col-md-8 lf-col p-0">
                        <div className="lf-outer-con">
                            {resetPasswordAllData?.form_fields?.map((i) => (<h2 className='lf-heading'>{language ? i?.name_hi : i?.name_en}</h2>))}
                            <div className="lf-inner">
                                <div className="lf-outer">
                                    {resetPasswordFormData?.map((inp) => {
                                        return (
                                            <>
                                                {inp?.id === 2 || inp?.input_field_name === "password" ?
                                                    <>
                                                        {/* New Password */}
                                                        < div className='lf-input-box mb-4'>
                                                            <div className="used-icon-password-visible">
                                                                <img className='lg-input-icon' src={`${ImageurlIcons()}/icons/${inp?.icon}`} alt="" />
                                                                <input
                                                                    className='w-100'
                                                                    placeholder={language ? inp?.name_hi : inp?.name_en}
                                                                    type={passwordVisible ? 'text' : "password"}
                                                                    name="newPassword"
                                                                    onKeyUp={(e) => validateonKeyUpHandle(inp, e.target.value, resetPasswordFormData, setResetPasswordFormData)}
                                                                    onChange={(e) => {
                                                                        validateonChangeHandle(inp, e, resetPasswordFormData, setResetPasswordFormData)
                                                                    }}
                                                                    value={inp.value}
                                                                    onCopy={(e) => e.preventDefault()}
                                                                    onCut={(e) => e.preventDefault()}
                                                                    onPaste={(e) => e.preventDefault()}
                                                                />
                                                                {
                                                                    passwordVisible ?
                                                                        <BsEye className="icon-password-visible" onClick={() => setPasswordVisile(!passwordVisible)} /> :
                                                                        <BsEyeSlash className="icon-password-visible" onClick={() => setPasswordVisile(!passwordVisible)} />
                                                                }
                                                            </div>
                                                            {inp?.error && (
                                                                <p className='validate-error'>{language ? inp?.error?.hi : inp?.error?.en}</p>
                                                            )}
                                                        </div >
                                                    </>
                                                    : inp?.id === 3 || inp?.input_field_name === "confirm_password" ? <>
                                                        {/* Confirm Password */}
                                                        <div className='lf-input-box'>
                                                            <div className="used-icon-password-visible">
                                                                <img className='lg-input-icon' src={`${ImageurlIcons()}/icons/${inp?.icon}`} alt="" />
                                                                <input
                                                                    className='w-100'
                                                                    placeholder={language ? inp?.name_hi : inp?.name_en}
                                                                    type={confirmPasswordVisible ? 'text' : "password"}
                                                                    name="confirmPassword"
                                                                    value={inp.value}
                                                                    onKeyUp={(e) => validateonKeyUpHandle(inp, e.target.value, resetPasswordFormData, setResetPasswordFormData)}
                                                                    onChange={(e) => {
                                                                        validateonChangeHandle(inp, e, resetPasswordFormData, setResetPasswordFormData)
                                                                    }}
                                                                    onCopy={(e) => e.preventDefault()}
                                                                    onCut={(e) => e.preventDefault()}
                                                                    onPaste={(e) => e.preventDefault()}
                                                                />
                                                                {
                                                                    confirmPasswordVisible ?
                                                                        <BsEye className="icon-password-visible" onClick={() => setConfirmPasswordVisile(!confirmPasswordVisible)} /> :
                                                                        <BsEyeSlash className="icon-password-visible" onClick={() => setConfirmPasswordVisile(!confirmPasswordVisible)} />
                                                                }
                                                            </div>
                                                            {inp?.error && (
                                                                <p className='validate-error'>{language ? inp?.error?.hi : inp?.error?.en}</p>
                                                            )}
                                                            {passwordWrongMessage && (
                                                                <p className='validate-error'>{language ? passwordWrongMessage?.hi : passwordWrongMessage?.en}</p>
                                                            )}                                                  </div>
                                                    </> : null
                                                }
                                            </>
                                        )
                                    })}
                                    {resetPasswordAllData?.button_fields?.map((butt) => {
                                        return (
                                            <>
                                                {!allFieldsValid() || (passwordWrongMessage?.hi == "पासवर्ड मेल नहीं खाया" || passwordWrongMessage?.en == "Password didn't match") ? (
                                                    <div className="lf-btn">
                                                        <p className='validate-error text-center mb-2'>
                                                            {language ? emptyClickMessage?.hi : emptyClickMessage?.en}
                                                        </p>
                                                        <button onClick={hadleShowMessgae}>
                                                            {language ? butt?.name_hi : butt?.name_en}
                                                        </button>
                                                    </div>
                                                ) : (
                                                    <div className="lf-btn border-0">
                                                        <button onClick={handleSubmitLogin}>
                                                            {language ? butt?.name_hi : butt?.name_en}
                                                        </button>
                                                    </div>
                                                )}
                                            </>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}
export default ResetPasswordForm