import React, { useEffect, useState } from 'react'
import HeaderBottom from '../../components/Header/HeaderBottom'
import DashboardHeader from '../../components/Dashboard/DashboardHeader/DashboardHeader'
import DasboardSidebar from '../../components/Dashboard/DashboardSidebar/DasboardSidebar'
import './Dashboard.css'
import { HomeIcon } from '../../components/Application/ApplicationIcon'
import { useLocation, useNavigate } from 'react-router-dom'
import { GetLocalStorage, GetLocalStorageParsed } from '../../Utils/Utils'
import { useLanguage } from '../../components/LanguageProvider/LanguageProvider '
const Dashboard = ({ children,first,head }) => {

const {language,setLanguage}=useLanguage();
    const location = useLocation();
    const pathname = location.pathname;
  const userDetail = GetLocalStorageParsed("userDetail");
  const token = GetLocalStorage("userInfo");
  const getTokenExpiry= GetLocalStorageParsed("tokenExpiry");

  const navigate=useNavigate();


  useEffect(()=>{
    if(!token ){
        // if()
        navigate("/")
    }
  },[])
  useEffect(()=>{
    if(getTokenExpiry){
        if(new Date(getTokenExpiry) < new Date()){
        
            navigate("/")
        }
    }
 
  },[getTokenExpiry])



    // Remove the leading slash
    const cleanedPathname = pathname.startsWith('/') ? pathname.substring(1) : pathname;

    const [sidebarCollapse, setSideBarCollapse] = useState(false);
    return (
        <>
            <DashboardHeader sidebarCollapse={sidebarCollapse} setSideBarCollapse={setSideBarCollapse} userDetail={userDetail}/>
            <div className={sidebarCollapse === true ? 'd-flex dash-main-div dash-main-coll' : " d-flex dash-main-div"}>
                <div className={sidebarCollapse === true ? 'ds-cont ds-cont-coll' : "ds-cont"}>
                    <DasboardSidebar sidebarCollapse={sidebarCollapse} setSideBarCollapse={setSideBarCollapse} />
                </div>
                <div className="col-md-12 position-relative">
                    <div className='ap-breadcrumb'>
                        <HomeIcon />{first}  /  <span>{head}</span>
                    </div>
                    <div className='mb-5'>
                    {children}
                    </div>
                    <div className='ds-footer'>{language ? "कॉपीराइट © 2024 उत्तराखंड पर्यटन विकास बोर्ड। - सर्वाधिकार सुरक्षित।":"Copyright © 2024 Uttarakhand Tourism Development Board. - All rights reserved."}</div>
                </div>
            </div>
        </>
    )
}
export default Dashboard