import React, { useEffect, useState } from 'react'
import "../Admin.css"
import { DeleteIcon, EditIcon, UploadIcon } from '../AdminIcon'
import { Modal, Table } from 'react-bootstrap'
import { Apiurl, GetLocalStorage, Imageurl } from '../../../Utils/Utils'
import axios from 'axios'
import Select from 'react-select';
import AdminDashboard from '../AdminDashboard/AdminDashboard'
import { useNavigate } from 'react-router-dom'
const SubCategory = () => {
  const token = GetLocalStorage("userInfo")
  const navigate = useNavigate()
  const [name, setName] = useState();
  const [nameError, setNameError] = useState();

  const [nameHi, setNameHi] = useState();
  const [nameHiError, setNameHiError] = useState();

  const [description, setDescription] = useState();
  const [descriptionError, setDescriptionError] = useState();

  const [image, setImage] = useState();
  const [imageError, setImageError] = useState();

  const [fontFamily, setFontFamily] = useState();
  const [fontFamilyError, setFontFamilyError] = useState();

  const [fontColor, setFontColor] = useState();
  const [fontColorError, setFontColorError] = useState();

  const [fontSize, setFontSize] = useState();
  const [fontSizeError, setFontSizeError] = useState();

  const [backgroundColor, setBackgroundColor] = useState();
  const [backgroundColorError, setBackgroundColorError] = useState();

  const [categoryId, setCategoryId] = useState()
  const [categoryIdError, setCategoryIdError] = useState()

  const [categoryList, setCategoryList] = useState([])
  const [subCategoryList, setSubCategoryList] = useState([])
  const [editData, setEditData] = useState()
  const [deleteData, setDeleteData] = useState()

  const [fontsList, setFontsList] = useState()
  const handleKeyUpLoginDetails = (item) => {
    validateForm(item);
  };
  //validations
  const validateForm = (item) => {
    let isValid = true;
    if (item === "category" || item === "both") {
      if (!categoryId) {
        setCategoryIdError("Please Select Category");
        isValid = false;
      } else {
        setCategoryIdError("");
      }
    }

    if (item === "name" || item === "both") {
      // if (!categoryId) {
      //   setCategoryIdError("Please Select Category");
      //   isValid = false;
      // }
      if (!name || name.trim() === "") {
        setNameError("Please Enter English Name");
        isValid = false;
      } else {
        setNameError("");
      }
    }
    if (item === "nameHi" || item === "both") {
      if (!nameHi || nameHi.trim() === "") {
        setNameHiError("Please Enter Hindi Name");
        isValid = false;
      } else {
        setNameHiError("");
      }
    }
    if (item === "desc" || item === "both") {
      // if (!name || name.trim() === "") {
      //   setNameError("Please Enter Name");
      //   isValid = false;
      // }
      if (!description || description.trim() === "") {
        setDescriptionError("Please Enter Description");
        isValid = false;
      } else {
        setDescriptionError("");
      }
    }

    if (item === "img" || item === "both") {
      // if (!description || description.trim() === "") {
      //   setDescriptionError("Please Enter Description");
      //   isValid = false;
      // }
      if (!image) {
        setImageError("Please Upload Image");
        isValid = false;
      } else {
        setImageError("");
      }
    }

    if (item === "family" || item === "both") {
      // if (!image) {
      //   setImageError("Please Upload Image");
      //   isValid = false;
      // }
      if (!fontFamily) {
        setFontFamilyError("Please Select Font Family");
        isValid = false;
      } else {
        setFontFamilyError("");
      }
    }

    if (item === "color" || item === "both") {
      // if (!fontFamily) {
      //   setFontFamilyError("Please Select Font Family");
      //   isValid = false;
      // }
      if (!fontColor || fontColor.trim() === "") {
        setFontColorError("Please Select Font Color");
        isValid = false;
      } else {
        setFontColorError("");
      }
    }

    if (item === "size" || item === "both") {
      // if (!fontColor || fontColor.trim() === "") {
      //   setFontColorError("Please Select Font Color");
      //   isValid = false;
      // }
      if (!fontSize || isNaN(fontSize) || fontSize <= 0) {
        setFontSizeError("Please Enter Valid Font Size");
        isValid = false;
      } else {
        setFontSizeError("");
      }
    }

    if (item === "bgcolor" || item === "both") {
      // if (!fontSize || isNaN(fontSize) || fontSize <= 0) {
      //     setFontSizeError("Please Enter Valid Font Size");
      //     isValid = false;
      // } 
      if (!backgroundColor || backgroundColor.trim() === "") {
        setBackgroundColorError("Please Select Background Color");
        isValid = false;
      } else if (backgroundColor == fontColor) {
        setBackgroundColorError("Font color and background color cannot be same.");
        isValid = false;
      } else {
        setBackgroundColorError("");
      }
    }

    return isValid;
  };
  // add sub-Category
  const addSubCategory = async () => {
    if (!validateForm("both")) return
    try {
      const formData = new FormData();
      formData.append("category_id", categoryId.value)
      formData.append("category_name", name)
      formData.append("category_name_hi", nameHi)
      formData.append("category_description", description)
      formData.append("category_image", image)
      formData.append("font_family_id", fontFamily?.value)
      formData.append("font_color", fontColor)
      formData.append("font_size", fontSize)
      formData.append("background_color", backgroundColor)

      const option = {
        method: "POST",
        headers: {
          'Content-Type': 'multipart/form-data',
          'access-control-allow-origin': '*',
          "authorization": `Bearer ${token}`
        },
        url: `${Apiurl()}/admin/categories/create`,
        data: formData
      };

      const response = await axios(option)
      setName("")
      setNameHi("")
      setDescription("")
      setImage("")
      setCategoryId()
      setFontFamily("");
      setFontColor("")
      setFontSize("")
      setBackgroundColor("")
      getSubCategoryList()
    } catch (error) {
      console.error(error);
    }
  }
  //category list
  const getCategoryList = () => {
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "authorization": `Bearer ${token}`
      },
      url: `${Apiurl()}/admin/categories`,
    };
    axios(option)
      .then((e) => {
        const data = e?.data?.data?.filter(i => i?.status === "1")?.map(item => ({
          value: item?.id,
          label: item?.category_name
        }))

        setCategoryList(data)

      })
      .catch((error) => {
        navigate("/")
      });
  };
  // sub category list
  const getSubCategoryList = () => {
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "authorization": `Bearer ${token}`
      },
      url: `${Apiurl()}/admin/sub-categories`,
    };
    axios(option)
      .then((e) => {
        const data = e?.data?.data?.filter(i => i?.status === "1")

        setSubCategoryList(data)

      })
      .catch((error) => {

      });
  };
  //get font families
  const getFontFamily = () => {
    const option = {
      method: "GET",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        "authorization": `Bearer ${token}`
      },
      url: `${Apiurl()}/get-fontfamilies`,
    };
    axios(option)
      .then((e) => {
        const data = e?.data?.data?.map(i => ({
          label: i?.name, value: i?.id
        }))

        setFontsList(data)

      })
      .catch((error) => {

      });
  };

  useEffect(() => {
    getFontFamily()
    getCategoryList()
    getSubCategoryList()
  }, [])

  // delete sub-Category
  const deleteSubCategory = async (item) => {
    try {
      const formData = new FormData();
      formData.append("parent_category_id", item?.parent_category_id)
      formData.append("category_id", item?.id)
      const option = {
        method: "POST",
        headers: {
          'Content-Type': 'multipart/form-data',
          'access-control-allow-origin': '*',
          "authorization": `Bearer ${token}`
        },
        url: `${Apiurl()}/admin/categories/delete`,
        data: formData
      };

      const response = await axios(option)
      getSubCategoryList()
    } catch (error) {
      console.error(error);
    }
  }
  //edit sub-category
  const editSubCategory = (item) => {
    setEditData(item)
    setName(item?.category_name);
    setNameHi(item?.category_name_hi);
    setDescription(item?.category_description)
    setImage(item?.category_image)
    setCategoryId(categoryList?.find(i => i.value == item?.parent_category_id))
    setFontFamily(fontsList?.find(i => i?.value == item?.font_family_id));
    setFontColor(item?.font_color)
    setFontSize(item?.font_size)
    setBackgroundColor(item?.background_color)
    window.scrollTo(0, 0);
  }
  //update sub-category
  const updateSubCategory = async () => {
    if (!validateForm("both")) return
    try {
      const formData = new FormData();
      formData.append("parent_category_id", editData?.parent_category_id)
      formData.append("category_id", editData?.id)
      formData.append("category_name", name)
      formData.append("category_name_hi", nameHi)
      formData.append("category_description", description)
      formData.append("category_image", image)
      formData.append("font_family_id", fontFamily?.value)
      formData.append("font_color", fontColor)
      formData.append("font_size", fontSize)
      formData.append("background_color", backgroundColor)
      const option = {
        method: "POST",
        headers: {
          'Content-Type': 'multipart/form-data',
          'access-control-allow-origin': '*',
          "authorization": `Bearer ${token}`
        },
        url: `${Apiurl()}/admin/categories/update`,
        data: formData
      };

      const response = await axios(option)
      setName("")
      setNameHi("")
      setDescription("")
      setImage("")
      setEditData("")
      setCategoryId()
      setFontFamily("");
      setFontColor("")
      setFontSize("")
      setBackgroundColor("")
      getSubCategoryList()
    } catch (error) {
      console.error(error);
    }
  }
  const handleEmptyData = () => {
    setName("")
    setNameHi("")
    setDescription("")
    setImage("")
    setEditData("")
    setCategoryId()
    setFontFamily("");
    setFontColor("")
    setFontSize("")
    setBackgroundColor("")
    setNameError("")
    setNameHiError("")
    setDescriptionError("")
    setImageError("")
    setFontFamilyError("");
    setFontColorError("")
    setFontSizeError("")
    setBackgroundColorError("")
    setCategoryIdError()
  }
  const handleFileChange = (e) => {
    const file = e?.target?.files?.[0];
    if (file) {
      if (file.type.startsWith('image/')) {
        setImage(file);
        setImageError('');
      } else {
        setImage(null);
        setImageError('Please upload an image file.');
        e.target.value = '';
      }
    }
  };
  return (
    <AdminDashboard>
      <div className='aod-outer'>
        <div className="aod-inner">
          <div className={editData?.id ? "aod-update aod-bottom" : "aod-bottom"}>
            <div className="aod-head">{editData?.id ? "Update" : "Add"} Sub Category</div>
            <div className="row py-4">
              <div className="col-md-4 aod-resp-tab">
                <div className='suf-input-box aod-form-input mb-2'>
                  <label className='lf-label w-100'>
                    Select Category
                  </label>
                  <Select
                    className='menu_open_select'
                    classNamePrefix='ad-menu_open_select_class'
                    aria-label="Default select example"
                    value={categoryId}
                    options={categoryList}
                    onChange={(e) => { setCategoryId(e); setCategoryIdError("") }}
                    placeholder="Select Category"
                    onMenuOpen={() => handleKeyUpLoginDetails("category")}
                    onMenuClose={() => handleKeyUpLoginDetails("category")}
                  />
                  {categoryIdError && <p className='validate-error'>{categoryIdError}</p>}
                </div>
              </div>
              <div className="col-md-4 aod-resp-tab">
                <div className='suf-input-box aod-form-input mb-2'>

                  <label className='mb-1 w-100'>
                    Sub Category English Name
                  </label>
                  <input className='w-100' placeholder='Enter Category English Name' type="text" onKeyUp={() => handleKeyUpLoginDetails("name")} value={name} onChange={(e) => setName(e?.target?.value)} />
                  {nameError && <p className='validate-error'>{nameError}</p>}

                </div>
              </div>
              <div className="col-md-4 aod-resp-tab">
                <div className='suf-input-box aod-form-input mb-2'>

                  <label className='mb-1 w-100'>
                    Sub Category Hindi Name
                  </label>
                  <input className='w-100' placeholder='Enter Category Name' type="text" onKeyUp={() => handleKeyUpLoginDetails("nameHi")} value={nameHi} onChange={(e) => setNameHi(e?.target?.value)} />
                  {nameHiError && <p className='validate-error'>{nameHiError}</p>}

                </div>
              </div>
              <div className="col-md-4 aod-resp-tab">
                <div className='suf-input-box aod-form-input mb-2'>

                  <label className='mb-1 w-100'>
                    Sub Category Description
                  </label>
                  <input className='w-100' placeholder='Enter Category Description' type="text" onKeyUp={() => handleKeyUpLoginDetails("desc")} value={description} onChange={(e) => setDescription(e?.target?.value)} />

                  {descriptionError && <p className='validate-error'>{descriptionError}</p>}

                </div>
              </div>
              <div className="col-md-4 aod-resp-tab">
                <div className='suf-input-box aod-form-input mb-2'>
                  <label className='mb-1 w-100'>
                    Sub Category Image
                  </label>
                  <input type="file" name="partsuploadfile" id="profileimg" className="w-100 ad-cat-input-field-image" accept="image/*" onChange={(e) => handleFileChange(e)} />
                  <label htmlFor="profileimg" className="ad-cat-input-upload-image p-0">
                    <p className="ad-cat-text-upload-image">
                      <span><UploadIcon /></span> Choose Category Image
                    </p>
                  </label>
                  {image?.name ? <p className='validate-error'>{image?.name}</p> : imageError ? <p className='validate-error'>{imageError}</p> : ""}
                </div>
              </div>
              <div className="col-md-4 aod-resp-tab">
                <div className='suf-input-box aod-form-input mb-2'>
                  <label className='mb-1 w-100'>Font Family</label>
                  <Select
                    className='menu_open_select'
                    classNamePrefix='ad-menu_open_select_class'
                    aria-label="Default select example"
                    value={fontFamily}
                    options={fontsList}
                    onChange={(e) => { setFontFamily(e); setFontFamilyError(""); }}
                    placeholder="Select Font Family"
                    onMenuOpen={() => handleKeyUpLoginDetails("family")}
                    onMenuClose={() => handleKeyUpLoginDetails("family")}
                  />
                  {fontFamilyError && <p className='validate-error'>{fontFamilyError}</p>}
                </div>
              </div>
              <div className="col-md-4 aod-resp-tab">
                <div className='suf-input-box aod-form-input mb-2'>
                  <label className='mb-1 w-100'>Font Color</label>
                  <input className='w-100' placeholder='Enter Font Color' onKeyUp={() => handleKeyUpLoginDetails("color")} onBlur={() => handleKeyUpLoginDetails("color")} type="color" value={fontColor} onChange={(e) => setFontColor(e?.target?.value)} />
                  {fontColorError && <p className='validate-error'>{fontColorError}</p>}
                </div>
              </div>
              <div className="col-md-4 aod-resp-tab">
                <div className='suf-input-box aod-form-input mb-2'>
                  <label className='mb-1 w-100'>Font Size (in pixels)</label>
                  <input className='w-100' placeholder='Enter Font Size' type="number" value={fontSize} onKeyUp={() => handleKeyUpLoginDetails("size")}
                    onKeyDown={(e) => {
                      if (e.key === '-' || e.key === '+' || e.key === 'e' || e.key === 'E') {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value > 0) {
                        setFontSize(value);
                        setFontSizeError("")
                      } else {
                        setFontSize("");

                      }
                    }} />
                  {fontSizeError && <p className='validate-error'>{fontSizeError}</p>}
                </div>
              </div>
              <div className="col-md-4 aod-resp-tab">
                <div className='suf-input-box aod-form-input mb-2'>
                  <label className='mb-1 w-100'>Background Color</label>
                  <input className='w-100' placeholder='Enter Font Color' type="color" onKeyUp={() => handleKeyUpLoginDetails("bgcolor")} onBlur={() => handleKeyUpLoginDetails("bgcolor")} value={backgroundColor} onChange={(e) => setBackgroundColor(e?.target?.value)} />
                  {backgroundColorError && <p className='validate-error'>{backgroundColorError}</p>}
                </div>
              </div>

            </div>
            <div className="row ad-cat-row">
              {
                editData?.id ?
                  <>
                    <div className="col-md-7 gap-4 d-flex">
                      <button onClick={() => handleEmptyData()} className='suf-can-butt w-auto px-4'>
                        Cancel
                      </button>
                      <button onClick={() => updateSubCategory()}>
                        Update Sub Category
                      </button> </div></> :
                  <div className="col-md-4">
                    <button onClick={addSubCategory}>
                      Add Sub Category
                    </button></div>
              }
            </div>

          </div>
        </div>
        <div className="aod-inner pt-0">
          <div className="aod-bottom">
            <div className="aod-head">Sub Category List</div>
            <div className="ad-reg-table">
              <Table size="sm" className='ad-cat-table mt-4'>
                <thead>
                  <tr>
                    <th className='ad-sno'>S No</th>
                    <th className='ad-long'>Category Id</th>
                    <th className='ad-long'>Category Name</th>
                    <th className='ad-long'>Sub Category Id </th>
                    <th className='ad-long'>Sub Category Image</th>
                    <th className='ad-long'>Sub Category English Name</th>
                    <th className='ad-long'>Sub Category Hindi Name</th>
                    <th className='ad-long'>Sub Category Description</th>
                    <th className='ad-long'>Font Family</th>
                    <th className='ad-long'>Font Color</th>
                    <th className='ad-sno'>Font Size</th>
                    <th className='ad-long'>Background Color</th>
                    <th className='ad-long'>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    subCategoryList?.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item?.parent_category_id} </td>
                        <td>{categoryList?.find(i => i.value == item?.parent_category_id)?.label}</td>
                        <td>{item?.id}</td>
                        <td>{item?.category_image ? <img className='ad-cat-td-img sub-cat-img' src={`${Imageurl()}/${item?.category_image}`} alt="" /> : "--"}</td>
                        <td>{item?.category_name ? item?.category_name : "--"}</td>
                        <td>{item?.category_name_hi ? item?.category_name_hi : "--"}</td>
                        <td>{item?.category_description ? item?.category_description : "--"}</td>
                        <td>{item?.font_family_id ? fontsList?.find(i => i?.value == item?.font_family_id)?.label : "--"}</td>
                        <td>{item?.font_color ? item?.font_color : "--"}</td>
                        <td>{item?.font_size ? item?.font_size : "--"}</td>
                        <td>{item?.background_color ? item?.background_color : "--"}</td>
                        <td><span className='ad-cat-edit' onClick={() => { editSubCategory(item) }}><EditIcon /></span><span className='ad-cat-delete' onClick={() => setDeleteData(item)}><DeleteIcon /></span></td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
      <Modal show={deleteData?.id} onHide={() => setDeleteData("")}>
        <Modal.Header closeButton>
          <Modal.Title>Do you really want to delete sub this Category ?</Modal.Title>
        </Modal.Header>
        {/* <Modal.Body></Modal.Body> */}
        <Modal.Footer>
          <div className="suf-btn p-2">
            <button onClick={() => { deleteSubCategory(deleteData) }}>Yes</button>
            <button className='suf-can-butt' onClick={() => setDeleteData("")}>No</button>
          </div>
        </Modal.Footer>
      </Modal>
    </AdminDashboard>
  )
}

export default SubCategory
