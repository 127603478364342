import React, { useEffect, useState } from 'react'
import "../Admin.css"
import { DeleteIcon, EditIcon, UploadIcon } from '../AdminIcon'
import { Modal, Table } from 'react-bootstrap'
import { Apiurl, GetLocalStorage, Imageurl, RemoveSession } from '../../../Utils/Utils'
import axios from 'axios'
import Select from 'react-select';
import AdminDashboard from '../AdminDashboard/AdminDashboard'
import { useNavigate } from 'react-router-dom'
const ApplicationSections = () => {
    const navigate = useNavigate();
    const token = GetLocalStorage("userInfo")
    const [nameEn, setNameEn] = useState()
    const [nameHi, setNameHi] = useState()
    const [subCategoryId, setSubCategoryId] = useState()
    const [searchSubCategoryId, setSearchSubCategoryId] = useState()
    const [searchFormTabId, setSearchFormTabId] = useState()
    const [nameEnError, setNameEnError] = useState()
    const [nameHiError, setNameHiError] = useState()
    const [subCategoryIdError, setSubCategoryIdError] = useState()
    const [subCategoryList, setSubCategoryList] = useState([])
    const [editData, setEditData] = useState()
    const [formTabId, setFormTabId] = useState()
    const [formTabIdError, setFormTabIdError] = useState()
    const [sectionList, setSectionList] = useState([])
    const [sectionFullList, setSectionFullList] = useState([])
    const [formTabList, setFormTabList] = useState()
    const [searchFormTabList, setSearchFormTabList] = useState()
    const [fontFamily, setFontFamily] = useState();
    const [fontFamilyError, setFontFamilyError] = useState();

    const [fontColor, setFontColor] = useState();
    const [fontColorError, setFontColorError] = useState();

    const [fontSize, setFontSize] = useState();
    const [fontSizeError, setFontSizeError] = useState();

    const [backgroundColor, setBackgroundColor] = useState();
    const [backgroundColorError, setBackgroundColorError] = useState();
    const [fontsList, setFontsList] = useState()
  const [deleteData, setDeleteData] = useState()

    const handleKeyUpLoginDetails = (item) => {
        validateForm(item);
    };
    //validations
    const validateForm = (item) => {
        let isValid = true;
        if (item === "subCategoryId" || item === "both") {
            if (!subCategoryId) {
                setSubCategoryIdError("Please Select Sub Category");
                isValid = false;
            } else {
                setSubCategoryIdError("");
            }
        }
        if (item === "formTabId" || item === "both") {
            // if (!subCategoryId) {
            //     setSubCategoryIdError("Please Select Sub Category");
            //     isValid = false;
            // }
            if (!formTabId) {
                setFormTabIdError("Please Select Form Tab");
                isValid = false;
            } else {
                setFormTabIdError("");
            }
        }
        if (item === "nameEn" || item === "both") {
            // if (!formTabId) {
            //     setFormTabIdError("Please Select Form Tab");
            //     isValid = false;
            // }
            if (!nameEn || nameEn.trim() === "") {
                setNameEnError("Please Enter English Name");
                isValid = false;
            } else {
                setNameEnError("");
            }
        }
        if (item === "nameHi" || item === "both") {
            // if (!nameEn || nameEn.trim() === "") {
            //     setNameEnError("Please Enter English Name");
            //     isValid = false;
            // }
            if (!nameHi || nameHi.trim() === "") {
                setNameHiError("Please Enter Hindi Name");
                isValid = false;
            } else {
                setNameHiError("");
            }
        }

        if (item === "family" || item === "both") {
            // if (!nameHi || nameHi.trim() === "") {
            //     setNameHiError("Please Enter Hindi Name");
            //     isValid = false;
            // }
            if (!fontFamily) {
                setFontFamilyError("Please Select Font Family");
                isValid = false;
            } else {
                setFontFamilyError("");
            }
        }

        if (item === "color" || item === "both") {
            // if (!fontFamily) {
            //     setFontFamilyError("Please Select Font Family");
            //     isValid = false;
            // }
            if (!fontColor || fontColor.trim() === "") {
                setFontColorError("Please Select Font Color");
                isValid = false;
            } else {
                setFontColorError("");
            }
        }

        if (item === "size" || item === "both") {
            // if (!fontColor || fontColor.trim() === "") {
            //     setFontColorError("Please Select Font Color");
            //     isValid = false;
            // }
            if (!fontSize || isNaN(fontSize) || fontSize <= 0) {
                setFontSizeError("Please Enter Valid Font Size");
                isValid = false;
            } else {
                setFontSizeError("");
            }
        }

        if (item === "bgcolor" || item === "both") {
            // if (!fontSize || isNaN(fontSize) || fontSize <= 0) {
            //     setFontSizeError("Please Enter Valid Font Size");
            //     isValid = false;
            // }
            if (!backgroundColor || backgroundColor.trim() === "") {
                setBackgroundColorError("Please Select Background Color");
                isValid = false;
            } else if (backgroundColor == fontColor) {
                setBackgroundColorError("Font color and background color cannot be same.");
                isValid = false;
            } else {
                setBackgroundColorError("");
            }
        }

        return isValid;
    };
    // add Category tab
    const addSubCategoryTab = async () => {
        if (!validateForm("both")) return
        try {
            const formData = new FormData();
            formData.append("sub_category_id", subCategoryId.value)
            formData.append("form_id", formTabId.value)
            formData.append("name_en", nameEn)
            formData.append("name_hi", nameHi)
            formData.append("sort", 10)
            formData.append("status", "1")
            formData.append("font_family_id", fontFamily?.value)
            formData.append("font_color", fontColor)
            formData.append("font_size", fontSize)
            formData.append("background_color", backgroundColor)
            const option = {
                method: "POST",
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'access-control-allow-origin': '*',
                    "authorization": `Bearer ${token}`
                },
                url: `${Apiurl()}/admin/categories/tabs/sections/create`,
                data: formData
            };

            const response = await axios(option)
            setNameEn("")
            setNameHi("")
            setSubCategoryId("")
            setFormTabId("")
            setFontFamily("");
            setFontColor("")
            setFontSize("")
            setBackgroundColor("")
            getSections()
        } catch (error) {

            console.error(error);
        }
    }
    // sub category list
    const getSubCategoryList = () => {
        const option = {
            method: "GET",
            headers: {
                "access-control-allow-origin": "*",
                "content-type": "application/json",
                "authorization": `Bearer ${token}`
            },
            url: `${Apiurl()}/admin/sub-categories`,
        };
        axios(option)
            .then((e) => {
                const data = e?.data?.data?.map(i => ({
                    label: i?.category_name, value: i?.id
                }))

                setSubCategoryList(data)

            })
            .catch((error) => {
                navigate("/")

            });
    };
    // tabs list
    const getFormTabList = (item) => {
        const formData = new FormData();
        if (item == "search") {
            formData.append("sub_category_id", searchSubCategoryId.value)
        } else {
            formData.append("sub_category_id", subCategoryId.value)

        }
        const option = {
            method: "POST",
            headers: {
                "access-control-allow-origin": "*",
                "content-type": "application/json",
                "authorization": `Bearer ${token}`
            },
            url: `${Apiurl()}/admin/categories/tabs/get-sub-category-tabs`,
            data: formData
        };
        axios(option)
            .then((e) => {
                const data = e?.data?.data?.map(i => ({
                    label: i?.form_name_en, value: i?.id
                }))
                if (item == "search") {
                    setSearchFormTabList(data)
                } else {
                    setFormTabList(data)

                }

            })
            .catch((error) => {
                navigate("/")
            });
    };
    //get font families
    const getFontFamily = () => {
        const option = {
            method: "GET",
            headers: {
                "access-control-allow-origin": "*",
                "content-type": "application/json",
                "authorization": `Bearer ${token}`
            },
            url: `${Apiurl()}/get-fontfamilies`,
        };
        axios(option)
            .then((e) => {
                const data = e?.data?.data?.map(i => ({
                    label: i?.name, value: i?.id
                }))

                setFontsList(data)

            })
            .catch((error) => {

            });
    };
    //get sections
    const getSections = () => {
        const option = {
            method: "GET",
            headers: {
                "access-control-allow-origin": "*",
                "content-type": "application/json",
                "authorization": `Bearer ${token}`
            },
            url: `${Apiurl()}/admin/categories/tabs/sections`,
        };
        axios(option)
            .then((e) => {
                const data = e?.data?.data

                setSectionList(data)
                setSectionFullList(data)

            })
            .catch((error) => {

            });
    };
    useEffect(() => {
        getSections()
        getFontFamily()
        getSubCategoryList()
    }, [])
    useEffect(() => {
        if (subCategoryId?.value) {
            getFormTabList(null)
        } else if (searchSubCategoryId?.value) {
            getFormTabList("search")
        }
    }, [subCategoryId, searchSubCategoryId])
    // delete sub-Category
    const deleteApplicationTab = async (item) => {
        try {
            const formData = new FormData();
            formData.append("section_id", item?.id)
            const option = {
                method: "POST",
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'access-control-allow-origin': '*',
                    "authorization": `Bearer ${token}`
                },
                url: `${Apiurl()}/admin/categories/tabs/sections/delete`,
                data: formData
            };

            const response = await axios(option)
            getSections()
        } catch (error) {
            console.error(error);
        }
    }
    //edit section
    const editSubCategory = (item) => {
        setEditData(item)
        setSubCategoryId(subCategoryList?.find(i => i?.value == item?.category_id))
        setFormTabId(formTabList?.find(i => i?.value == item?.form_id))
        setNameEn(item?.name_en);
        setNameHi(item?.name_hi)
        setFontFamily(fontsList?.find(i => i?.value == item?.font_family_id));
        setFontColor(item?.font_color)
        setFontSize(item?.font_size)
        setBackgroundColor(item?.background_color)
        window.scrollTo(0, 0);
    }
    //update section
    const updateSubCategoryTab = async () => {
        if (!validateForm("both")) return
        try {
            const formData = new FormData();
            formData.append("section_id", editData?.id)
            formData.append("sub_category_id", subCategoryId.value)
            formData.append("form_id", formTabId.value)
            formData.append("name_en", nameEn)
            formData.append("name_hi", nameHi)
            formData.append("sort", 10)
            formData.append("status", "1")
            formData.append("font_family_id", fontFamily?.value)
            formData.append("font_color", fontColor)
            formData.append("font_size", fontSize)
            formData.append("background_color", backgroundColor)
            const option = {
                method: "POST",
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'access-control-allow-origin': '*',
                    "authorization": `Bearer ${token}`
                },
                url: `${Apiurl()}/admin/categories/tabs/update`,
                data: formData
            };

            const response = await axios(option)
            setNameEn("")
            setNameHi("")
            setSubCategoryId("")
            setFormTabId("")
            setFontFamily("");
            setFontColor("")
            setFontSize("")
            setBackgroundColor("")
            setEditData("")
            getFormTabList()
        } catch (error) {
            console.error(error);
        }
    }
    const handleEmptyData = () => {
        setNameEn("")
        setNameHi("")
        setSubCategoryId("")
        setFontFamily("");
        setFontColor("")
        setFormTabId("")
        setFormTabIdError("")
        setFontSize("")
        setBackgroundColor("")
        setNameEnError("")
        setNameHiError("")
        setSubCategoryIdError("")
        setFontFamilyError("");
        setFontColorError("")
        setFontSizeError("")
        setBackgroundColorError("")
        setEditData()
    }
    const handleEnableDisable = async (e, item) => {
        try {
            const formData = new FormData();
            formData.append("section_id", item?.id)
            formData.append("status", e?.value)
            const option = {
                method: "POST",
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'access-control-allow-origin': '*',
                    "authorization": `Bearer ${token}`
                },
                url: `${Apiurl()}/admin/categories/tabs/sections/enable-disable`,
                data: formData
            };

            const response = await axios(option)
            getSections()
        } catch (error) {
            console.error(error);
        }
    }
    useEffect(() => {
        let data = [...sectionFullList]
        if (searchSubCategoryId) {
            data = data?.filter(item => item?.category_id == searchSubCategoryId?.value)
        }
        if (searchFormTabId) {
            data = data?.filter(item => item?.form_id == searchFormTabId?.value)
        }
        setSectionList(data)

    }, [searchSubCategoryId, searchFormTabId])
    const resetFilter = () => {
        setSearchFormTabId("");
        setSearchSubCategoryId("");
        setSearchFormTabList([])
        setSectionList(sectionFullList)
    }
    return (
        <AdminDashboard>
            <div className='aod-outer'>
                <div className="aod-inner">
                    <div className={editData?.id ? "aod-update aod-bottom" : "aod-bottom"}>
                        <div className="aod-head">{editData?.id ? "Update" : "Add"} Section</div>
                        <div className="row py-4">
                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>
                                    <label className='lf-label w-100'>
                                        Select Sub Category
                                    </label>
                                    <Select
                                        className='menu_open_select'
                                        classNamePrefix='ad-menu_open_select_class'
                                        aria-label="Default select example"
                                        isDisabled={editData?.id}
                                        value={subCategoryId}
                                        options={subCategoryList}
                                        onChange={(e) => { setSubCategoryId(e); setSubCategoryIdError("") }}
                                        placeholder="Select Sub Category"
                                        onMenuOpen={() => handleKeyUpLoginDetails("subCategoryId")}
                                        onMenuClose={() => handleKeyUpLoginDetails("subCategoryId")}
                                    />
                                    {subCategoryIdError && <p className='validate-error'>{subCategoryIdError}</p>}
                                </div>
                            </div>
                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>
                                    <label className='lf-label w-100'>
                                        Select Form Tab
                                    </label>
                                    <Select
                                        className='menu_open_select'
                                        classNamePrefix='ad-menu_open_select_class'
                                        aria-label="Default select example"
                                        isDisabled={editData?.id}
                                        value={formTabId}
                                        options={formTabList}
                                        onChange={(e) => { setFormTabId(e); setFormTabIdError("") }}
                                        placeholder="Select Form Tab"
                                        onMenuOpen={() => handleKeyUpLoginDetails("formTabId")}
                                        onMenuClose={() => handleKeyUpLoginDetails("formTabId")}
                                    />
                                    {formTabIdError && <p className='validate-error'>{formTabIdError}</p>}
                                </div>

                            </div>
                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>

                                    <label className='mb-1 w-100'>
                                        Section English Name
                                    </label>
                                    <input className='w-100' placeholder='Enter Section English Name' type="text" onKeyUp={() => handleKeyUpLoginDetails("nameEn")} value={nameEn} onChange={(e) => setNameEn(e?.target?.value)} />

                                    {nameEnError && <p className='validate-error'>{nameEnError}</p>}

                                </div>
                            </div>
                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>

                                    <label className='mb-1 w-100'>
                                        Section Hindi Name
                                    </label>
                                    <input className='w-100' placeholder='Enter Section Hindi Name' type="text" onKeyUp={() => handleKeyUpLoginDetails("nameHi")} value={nameHi} onChange={(e) => setNameHi(e?.target?.value)} />

                                    {nameHiError && <p className='validate-error'>{nameHiError}</p>}
                                </div>
                            </div>

                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>
                                    <label className='mb-1 w-100'>Font Family</label>
                                    <Select
                                        className='menu_open_select'
                                        classNamePrefix='ad-menu_open_select_class'
                                        aria-label="Default select example"
                                        value={fontFamily}
                                        options={fontsList}
                                        onChange={(e) => { setFontFamily(e); setFontFamilyError("") }}
                                        placeholder="Select Font Family"
                                        onMenuOpen={() => handleKeyUpLoginDetails("family")}
                                        onMenuClose={() => handleKeyUpLoginDetails("family")}
                                    />
                                    {fontFamilyError && <p className='validate-error'>{fontFamilyError}</p>}
                                </div>
                            </div>
                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>
                                    <label className='mb-1 w-100'>Font Color</label>
                                    <input className='w-100' placeholder='Enter Font Color' onKeyUp={() => handleKeyUpLoginDetails("color")} onBlur={() => handleKeyUpLoginDetails("color")} type="color" value={fontColor} onChange={(e) => setFontColor(e?.target?.value)} />
                                    {fontColorError && <p className='validate-error'>{fontColorError}</p>}
                                </div>
                            </div>
                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>
                                    <label className='mb-1 w-100'>Font Size (in pixels)</label>
                                    <input className='w-100' placeholder='Enter Font Size' type="number" value={fontSize} onKeyUp={() => handleKeyUpLoginDetails("size")}
                                        onKeyDown={(e) => {
                                            if (e.key === '-' || e.key === '+' || e.key === 'e' || e.key === 'E') {
                                                e.preventDefault();
                                            }
                                        }}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (value > 0) {
                                                setFontSize(value);
                                                setFontSizeError("")
                                            } else {
                                                setFontSize("");

                                            }
                                        }} />
                                    {fontSizeError && <p className='validate-error'>{fontSizeError}</p>}
                                </div>
                            </div>
                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>
                                    <label className='mb-1 w-100'>Background Color</label>
                                    <input className='w-100' placeholder='Enter Font Color' type="color" onKeyUp={() => handleKeyUpLoginDetails("bgcolor")} onBlur={() => handleKeyUpLoginDetails("bgcolor")} value={backgroundColor} onChange={(e) => setBackgroundColor(e?.target?.value)} />
                                    {backgroundColorError && <p className='validate-error'>{backgroundColorError}</p>}
                                </div>
                            </div>
                        </div>
                        <div className="row ad-cat-row">
                            {
                                editData?.id ?
                                    <>
                                        <div className="col-md-6 gap-4 d-flex">
                                            <button onClick={() => handleEmptyData()} className='suf-can-butt w-auto px-4'>
                                                Cancel
                                            </button>
                                            <button onClick={() => updateSubCategoryTab()}>
                                                Update Section
                                            </button> </div></> :
                                    <div className="col-md-3">
                                        <button onClick={addSubCategoryTab}>
                                            Add Section
                                        </button></div>
                            }
                        </div>

                    </div>
                </div>
                <div className="aod-inner pt-0">
                    <div className="aod-bottom">
                        <div className="aod-head">Section List</div>
                        <div className="row py-4">
                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>
                                    <Select
                                        className='menu_open_select'
                                        classNamePrefix='ad-menu_open_select_class'
                                        aria-label="Default select example"
                                        value={searchSubCategoryId}
                                        options={subCategoryList}
                                        onChange={(e) => setSearchSubCategoryId(e)}
                                        placeholder="Select Sub Category"
                                    />
                                </div>
                            </div>
                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>
                                    <Select
                                        className='menu_open_select'
                                        classNamePrefix='ad-menu_open_select_class'
                                        aria-label="Default select example"
                                        isDisabled={editData?.id}
                                        value={searchFormTabId}
                                        options={searchFormTabList}
                                        onChange={(e) => setSearchFormTabId(e)}
                                        placeholder="Select Form Tab"
                                    />
                                </div>

                            </div>
                            <div className="col-md-1">
                                <button onClick={resetFilter} className='ad-reset-btn w-auto px-4'>
                                    Reset
                                </button></div>
                        </div>
                        <div className="ad-reg-table">
                            <Table size="sm" className='ad-cat-table'>
                                <thead>
                                    <tr>
                                        <th className='ad-sno'>S No</th>
                                        <th className='ad-sno'>Id</th>
                                        <th className='ad-long'>Sub Category Id </th>
                                        <th className='ad-long'>Form Id</th>
                                        <th className='ad-long'>English Name</th>
                                        <th className='ad-long'>Hindi Name</th>
                                        <th className='ad-long'>Font Family</th>
                                        <th className='ad-long'>Font Color</th>
                                        <th className='ad-sno'>Font Size</th>
                                        <th className='ad-long'>Background Color</th>
                                        <th className='ad-long'>Status</th>
                                        <th className='ad-long'>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        sectionList?.map((item, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{item?.id}</td>
                                                <td>{item?.category_id}</td>
                                                <td>{item?.form_id}</td>
                                                <td>{item?.name_en}</td>
                                                <td>{item?.name_hi}</td>
                                                <td>{item?.font_family_id ? fontsList?.find(i => i?.value == item?.font_family_id)?.label : "--"}</td>
                                                <td>{item?.font_color ? item?.font_color : "--"}</td>
                                                <td>{item?.font_size ? item?.font_size : "--"}</td>
                                                <td>{item?.background_color ? item?.background_color : "--"}</td>
                                                <td> <Select
                                                    className='menu_open_select'
                                                    classNamePrefix='ad-menu_open_select_class'
                                                    aria-label="Default select example"
                                                    value={{ label: item?.status == 1 ? "Active" : "Inactive", value: item?.status }}
                                                    options={[{ label: "Active", value: "1" }, { label: "Inactive", value: "0" }]}
                                                    onChange={(e) => handleEnableDisable(e, item)}
                                                    placeholder="Select Status"
                                                /></td>
                                                <td><span className='ad-cat-edit' onClick={() => { editSubCategory(item) }}><EditIcon /></span><span className='ad-cat-delete' onClick={() => setDeleteData(item)}><DeleteIcon /></span></td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={deleteData?.id} onHide={() => setDeleteData("")}>
                <Modal.Header closeButton>
                    <Modal.Title>Do you really want to delete this section ?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <div className="suf-btn p-2">
                        <button onClick={() => { deleteApplicationTab(deleteData) }}>Yes</button>
                        <button className='suf-can-butt' onClick={() => setDeleteData("")}>No</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </AdminDashboard>
    )
}

export default ApplicationSections
