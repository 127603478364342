import React from 'react'
import './DasboardSidebar.css'
import homebg from "../../../assets/images/home-cat-bg.png"
import { CgClipboard } from "react-icons/cg";
import { BiHome } from "react-icons/bi";

import { ApplocationDraftIcon, ApplocationFormIcon, CategoryIcon, CertificateIcon, DashboardIcon, FormIcon, PaymentIcon, TransactionIcon } from '../DashboardIcons'
import { useLocation, useNavigate } from 'react-router-dom';
import { useLanguage } from '../../LanguageProvider/LanguageProvider ';
const DasboardSidebar = () => {
  const {language,setLanguage}=useLanguage();
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;
  return (
    <div className="hcat-second-das pb-5">
      <div className="ds-left mt-2">
        <ul className='ds-ul'>
          <li onClick={() => navigate("/category")}>
            {/* <button className={pathName==="/guidelines" ?'ds-green' : 'ds-white'} > <CgClipboard />Dashboard <span></span></button> */}
            <button className={pathName === "/category" ||pathName === "/guidelines" ? 'bg_image_green' : 'bg_image_white'}  > <CgClipboard /> <span>{language ? "डैशबोर्ड" :"Dashboard"}</span></button>
          </li>
          <li onClick={() => navigate("/application/overview")}>
            <button className={pathName?.split("/")?.[1] === "application" ? 'bg_image_green' : 'bg_image_white'}  > <CgClipboard /> <span>{language ? "आवेदन फार्म" :"Application Form"}</span></button>
          </li>
          {/* <li>
            <button className='ds-white'><CgClipboard/>Application Draft<span></span></button>
            
          </li>
          <li>
            <button className='ds-white'><CgClipboard />Certificate <span></span></button>
          </li>
          <li>
            <button className='ds-white'><CgClipboard />Payment Receipt <span></span></button>
          </li>
          <li>
            <button className='ds-white'><CgClipboard /> Transactions <span></span></button>
          </li>
          <li>
            <button className='ds-white'><CgClipboard />Categories <span></span></button>
          </li>
          <li>
            <button className='ds-white'><CgClipboard />Forms <span></span></button>
          </li> */}
        </ul>

      </div>
    </div>
  )
}

export default DasboardSidebar
