import React from 'react'
import Dashboard from '../../pages/Dashboard/Dashboard';
import { AccomodationIcon } from './ApplicationIcon'
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';



const AdditionalDetails = ({tabsData, tabActiveData,inputFormData }) => {
  const navigate=useNavigate();
  return (
    <Dashboard>
    <div className='aod-outer'>
      <div className="aod-inner">
        <div className="aod-top">
          {tabsData?.map((tab) => {
            return (

              <button className={tabActiveData?.id === tab?.id ? 'green' : 'white'} onClick={() => { navigate(`/application/${tab?.tableName?.replace(/_/g, '-')}`) }}><AccomodationIcon />{tab?.form_name_en}</button>
            )
          })}

        </div>
        <div className="aod-bottom" >
          {inputFormData?.sections.filter((section) => section.section_key !== 'owners_partners') // Filter out the third section
            .map((section) => (
              <>
                <div className="aod-head">{section?.name_en}</div>
                <div className="row py-4">
                  {section.category_form_feilds?.map(field => (
                    <div className="col-md-4" key={field.id}>
                      <div className='suf-input-box aod-form-input mb-2'>
                        <label className='mb-1 w-100'>{field.name_en}</label>
                        {field?.type === 'text' ?
                          <>
                            <input
                              className='w-100'
                              // onChange={(e) => handleFieldChange(e, item?.id)}
                              value={field?.value || " "}


                              placeholder={`Enter ${field.name_en}`}
                              type="text"
                              name={field.input_field_name}

                            />

                          </> : null

                        }
                        {
                          field?.type === "calender" ?
                            <div className='suf-input-box mb-2'>
                            
                              <input className='w-100' type="date"  />
                           

                            </div>: null
                      }
                        {field.type === 'dropdown' && (

                          <Select
                            className='menu_open_select'
                            classNamePrefix='menu_open_select_class'
                            aria-label="Default select example"

                            placeholder="Select State"
                          />




                        )
                        }
                        {field.type === 'file' && (
                          <>
                            <input type="file"
                              name={field.input_field_name}
                              id="profileimg"
                              className="w-100 suf-input-field-image"


                            />
                            <label htmlFor="profileimg" className="suf-input-upload-image"
                            >
                              <h5 className="para-parts-upload-image">
                                Choose Profile Photo
                              </h5>
                            </label>
                          </>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ))}


          <div className='od-butt'>
            {/* <button className='od-das-butt' >Dashboard</button> */}
            <button className='od-Nex-butt' >
              Next
            </button>
          </div>

        </div >

      </div>

    </div>
  </Dashboard>
  )
}

export default AdditionalDetails
