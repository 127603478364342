import React from 'react'
import appstatus from "../../../assets/images/app-status.png"
import { Link } from 'react-router-dom'
import HomebannerLogin from './HomebannerLogin'
import { useLanguage } from '../../LanguageProvider/LanguageProvider '
const HomeBanner = () => {
  const {language,setLanguage}=useLanguage()
  return (
    <section className='hb-bg-main'>
      <div className='hb-bg-inner'>
        <div className="container px-5">
          <div className='HomeBanner-main'>
            <div className='HB-first-box'>
              <p>{language? "उत्तराखंड के नागरिकों के लिए आवश्यक ऑनलाइन ट्रैवल ट्रेड सेवाएँ" :"Essential Online Travel Trade Services for the Citizens of Uttarakhand"}</p>
              <div className='btns-hb-main'>
                <div className='HB-first-box-btn'>
                  <div className='HB-btn-icon'> <img src={appstatus} alt="" /> </div>
                  <p>{language ? "अपने आवेदन की स्थिति जानें":"Know Your Application Status"}</p>
                </div>
                <div className='HB-first-box-btn'>
                  <div className='HB-btn-icon'> <img src={appstatus} alt="" /> </div>
                  <p>{language?"प्रमाणपत्र डाउनलोड करें/देखें":"Download / View Certificate"}</p>
                </div>
                <div className='HB-first-box-btn'>
                  <div className='HB-btn-icon'> <img src={appstatus} alt="" /> </div>
                  <p>{language ? "जिला कार्यालय":"District Office"}</p>
                </div>
              </div>
            </div>
            <div className='HB-second-box'>
              <HomebannerLogin />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default HomeBanner