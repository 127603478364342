import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { Apiurl, GetLocalStorage } from '../../../Utils/Utils';
import AdminDashboard from '../AdminDashboard/AdminDashboard';
import axios from 'axios';
import Select from 'react-select';
import { DeleteIcon, UploadIcon } from '../AdminIcon';
import { HomeIcon } from '../../Application/ApplicationIcon';
import { BsChevronLeft } from 'react-icons/bs';

const AdminResetPasswordAdd = () => {
    const navigate = useNavigate()
    const location=useLocation()
    const token = GetLocalStorage("userInfo")
    const [fontsList, setFontsList] = useState()

    //input fields
    const [editData, setEditData] = useState()
    const [name, setName] = useState();
    const [nameError, setNameError] = useState();

    const [nameHi, setNameHi] = useState();
    const [nameHiError, setNameHiError] = useState();

    const [pname, setPName] = useState();
    const [pnameError, setPNameError] = useState();

    const [pnameHi, setPNameHi] = useState();
    const [pnameHiError, setPNameHiError] = useState();

    const [image, setImage] = useState();
    const [imageError, setImageError] = useState();

    const [fontFamily, setFontFamily] = useState();
    const [fontFamilyError, setFontFamilyError] = useState();

    const [fontColor, setFontColor] = useState();
    const [fontColorError, setFontColorError] = useState();

    const [fontSize, setFontSize] = useState();
    const [fontSizeError, setFontSizeError] = useState();
    //button data
    const [editBtnData, setEditBtnData] = useState([])
    const [btnName, setBtnName] = useState();
    const [btnNameError, setBtnNameError] = useState();

    const [btnNameHi, setBtnNameHi] = useState();
    const [btnNameHiError, setBtnNameHiError] = useState();

    const [btnFontFamily, setBtnFontFamily] = useState();
    const [btnFontFamilyError, setBtnFontFamilyError] = useState();

    const [btnFontColor, setBtnFontColor] = useState();
    const [btnFontColorError, setBtnFontColorError] = useState();

    const [btnFontSize, setBtnFontSize] = useState();
    const [btnFontSizeError, setBtnFontSizeError] = useState();

    const [btnBackgroundColor, setBtnBackgroundColor] = useState();
    const [btnBackgroundColorError, setBtnBackgroundColorError] = useState();

    //validations fields
    const [showValidations, setShowValidations] = useState(false);
    const [preventData, setPreventData] = useState([{ id: Date.now(), type: '', unit: '', value: '', numberUpperLimit: '', numberLowerLimit: '', messageEnglish: '', messageHindi: '' }]);
    const [otherValidations, setOtherValidations] = useState([{ id: Date.now(), type: '', unit: "", value: '', numberUpperLimit: '', numberLowerLimit: '', messageEnglish: '', messageHindi: '' }]);
    const [validationsList, setValidationList] = useState([])
    const [validationsType, setValidationType] = useState([])
    const [validationError, setValidationError] = useState()
    useEffect(() => {
        if (location?.state && location?.pathname == "/admin/reset-password/update") {
            editCategory(location?.state)
        } else if (location?.state && location?.pathname == "/admin/reset-password/update-buttons") {
            editCategoryBtn(location?.state)
        }
    }, [location?.state, validationsList])
    //file change
    const handleFileChange = (e) => {
        const file = e?.target?.files?.[0];
        if (file) {
            if (file.type.startsWith('image/')) {
                setImage(file);
                setImageError('');
            } else {
                setImage(null);
                setImageError('Please upload an image file.');
                e.target.value = '';
            }
        }
    };
    const handleKeyUpLoginDetails = (item) => {
        validateForm(item);
    };
    //fields validations
    const validateForm = (item) => {
        let isValid = true;

        if (item === "name" || item === "both") {
            if (!name || name.trim() === "") {
                setNameError("Please Enter English Name");
                isValid = false;
            } else {
                setNameError("");
            }
        }
        if (item === "nameHi" || item === "both") {
            if (!nameHi || nameHi.trim() === "") {
                setNameHiError("Please Enter Hindi Name");
                isValid = false;
            } else {
                setNameHiError("");
            }
        }
        if (item === "pname" || item === "both") {
            if (!pname || pname.trim() === "") {
                setPNameError("Please Enter Placeholder English Name");
                isValid = false;
            } else {
                setPNameError("");
            }
        }
        if (item === "pnameHi" || item === "both") {
            if (!pnameHi || pnameHi.trim() === "") {
                setPNameHiError("Please Enter Placeholder Hindi Name");
                isValid = false;
            } else {
                setPNameHiError("");
            }
        }

        if (item === "img" || item === "both") {
            if (!image) {
                setImageError("Please Upload Image");
                isValid = false;
            } else {
                setImageError("");
            }
        }

        if (item === "family" || item === "both") {
            if (!fontFamily) {
                setFontFamilyError("Please Select Font Family");
                isValid = false;
            } else {
                setFontFamilyError("");
            }
        }

        if (item === "color" || item === "both") {
            if (!fontColor || fontColor.trim() === "") {
                setFontColorError("Please Select Font Color");
                isValid = false;
            } else {
                setFontColorError("");
            }
        }

        if (item === "size" || item === "both") {
            if (!fontSize || isNaN(fontSize) || fontSize <= 0) {
                setFontSizeError("Please Enter Valid Font Size");
                isValid = false;
            } else {
                setFontSizeError("");
            }
        }


        return isValid;
    };

    //get font families
    const getFontFamily = () => {
        const option = {
            method: "GET",
            headers: {
                "access-control-allow-origin": "*",
                "content-type": "application/json",
                "authorization": `Bearer ${token}`
            },
            url: `${Apiurl()}/get-fontfamilies`,
        };
        axios(option)
            .then((e) => {
                const data = e?.data?.data?.map(i => ({
                    label: i?.name, value: i?.id
                }))

                setFontsList(data)

            })
            .catch((error) => {

            });
    };
    useEffect(() => {
        getFontFamily()
    }, [])
    //edit fields
    const editCategory = (item) => {
        setEditData(item)
        setName(item?.name_en);
        setNameHi(item?.name_hi);
        setPName(item?.placeholder_en);
        setPNameHi(item?.placeholder_hi);
        setImage(item?.icon)
        setFontFamily(fontsList?.find(i => i?.value == item?.font_family_id));
        setFontColor(item?.font_color)
        setFontSize(item?.font_size)
        const validations = item?.validations ? JSON.parse(item?.validations) : null;
        const validationsparsed = JSON.parse(validations?.validation ? validations?.validation : "{}");
        if (validationsparsed) {
            setShowValidations({ label: 'Yes', value: true })
            const preventData = validationsparsed?.onChange?.length ? validationsparsed?.onChange?.map((item, index) => {
                const unitList = validationsList?.filter(i => i?.validation_type == item?.type)?.map(i => ({ label: i?.name, value: i?.id }))
                return ({
                    id: Date.now() + index,
                    type: item?.type ? validationsType?.find(i => i?.value == item?.type) : "",
                    unit: item?.unit ? unitList?.find(i => i?.label == item?.unit) : "",
                    value: item?.value,
                    numberUpperLimit: item?.numberUpperLimit,
                    numberLowerLimit: item?.numberLowerLimit,
                    messageEnglish: item?.messageEnglish,
                    messageHindi: item?.messageHindi

                })
            }) : [{ id: Date.now(), type: '', value: '', unit: '', numberUpperLimit: '', numberLowerLimit: '', messageEnglish: '', messageHindi: '' }]
            const otherData = validationsparsed?.onKeyUp?.length ? validationsparsed?.onKeyUp?.map((item, index) => {
                const unitList = validationsList?.filter(i => i?.validation_type == item?.type)?.map(i => ({ label: i?.name, value: i?.id }))
                return ({
                    id: Date.now() + index,
                    type: item?.type ? validationsType?.find(i => i?.value == item?.type) : "",
                    unit: item?.unit ? unitList?.find(i => i?.label == item?.unit) : "",
                    value: item?.value,
                    numberUpperLimit: item?.numberUpperLimit,
                    numberLowerLimit: item?.numberLowerLimit,
                    messageEnglish: item?.messageEnglish,
                    messageHindi: item?.messageHindi

                })
            }) : [{ id: Date.now(), type: '', value: '', unit: '', numberUpperLimit: '', numberLowerLimit: '', messageEnglish: '', messageHindi: '' }]
            setPreventData(preventData)
            setOtherValidations(otherData)
        } else {
            setShowValidations({ label: 'No', value: false })
        }
        window.scrollTo(0, 0);
    }
    //update fields
    const updateCategory = async () => {
        if (!validateForm("both")) return
        try {
            const formData = new FormData();
            formData.append("id", editData?.id)
            formData.append("name_en", name)
            formData.append("name_hi", nameHi)
            formData.append("placeholder_en", pname)
            formData.append("placeholder_hi", pnameHi)
            formData.append("icon", image)
            formData.append("font_family_id", fontFamily?.value)
            formData.append("font_color", fontColor)
            formData.append("font_size", fontSize)
            const preventdata = preventData?.filter(i => i?.type?.value)?.map(item => ({
                type: item?.type?.value, unit: item?.unit?.label, value: item?.value, numberUpperLimit: item?.numberUpperLimit, numberLowerLimit: item?.numberLowerLimit, messageEnglish: item?.messageEnglish, messageHindi: item?.messageHindi
            }))
            const otherdata = otherValidations?.filter(i => i?.type?.value)?.map(item => ({
                type: item?.type?.value, unit: item?.unit?.label, value: item?.value, numberUpperLimit: item?.numberUpperLimit, numberLowerLimit: item?.numberLowerLimit, messageEnglish: item?.messageEnglish, messageHindi: item?.messageHindi
            }))
            const validData = JSON.stringify({ validation: JSON.stringify({ onChange: preventdata, onKeyUp: otherdata }) })
            formData.append("validations", validData)
            const option = {
                method: "POST",
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'access-control-allow-origin': '*',
                    "authorization": `Bearer ${token}`
                },
                url: `${Apiurl()}/admin/reset-password-form/update`,
                data: formData
            };

            const response = await axios(option)
            navigate("/admin/reset-password")
        } catch (error) {
            console.error(error);
        }
    }

    //form buttons
    const handleKeyUpLoginDetailsBtn = (item) => {
        validateFormBtn(item);
    };
    //button validations
    const validateFormBtn = (item) => {
        let isValid = true;

        if (item === "name" || item === "both") {
            if (!btnName || btnName.trim() === "") {
                setBtnNameError("Please Enter English Name");
                isValid = false;
            } else {
                setBtnNameError("");
            }
        }
        if (item === "nameHi" || item === "both") {
            if (!btnNameHi || btnNameHi.trim() === "") {
                setBtnNameHiError("Please Enter Hindi Name");
                isValid = false;
            } else {
                setBtnNameHiError("");
            }
        }

        if (item === "family" || item === "both") {
            if (!btnFontFamily) {
                setBtnFontFamilyError("Please Select Font Family");
                isValid = false;
            } else {
                setBtnFontFamilyError("");
            }
        }

        if (item === "color" || item === "both") {
            if (!btnFontColor || btnFontColor.trim() === "") {
                setBtnFontColorError("Please Select Font Color");
                isValid = false;
            } else {
                setBtnFontColorError("");
            }
        }

        if (item === "size" || item === "both") {
            if (!btnFontSize || isNaN(btnFontSize) || btnFontSize <= 0) {
                setBtnFontSizeError("Please Enter Valid Font Size");
                isValid = false;
            } else {
                setBtnFontSizeError("");
            }
        }
        if (item === "bgcolor" || item === "both") {
            if (btnBackgroundColor == btnFontColor) {
                setBtnBackgroundColorError("Font color and background color cannot be same.");
                isValid = false;
            } else {
                setBtnBackgroundColorError("");
            }
        }

        return isValid;
    };
    //edit button 
    const editCategoryBtn = (item) => {
        setEditBtnData(item)
        setBtnName(item?.name_en);
        setBtnNameHi(item?.name_hi);
        setBtnFontFamily(fontsList?.find(i => i?.value == item?.font_family_id));
        setBtnFontColor(item?.font_color)
        setBtnFontSize(item?.font_size)
        setBtnBackgroundColor(item?.background_color)
        window.scrollTo(0, 0)
    }
    //update button
    const updateCategoryBtn = async () => {
        if (!validateFormBtn("both")) return
        try {
            const formData = new FormData();
            formData.append("id", editBtnData?.id)
            formData.append("name_en", btnName)
            formData.append("name_hi", btnNameHi)
            formData.append("font_family_id", btnFontFamily?.value)
            formData.append("font_color", btnFontColor)
            formData.append("font_size", btnFontSize)
            formData.append("background_color", btnBackgroundColor)
            const option = {
                method: "POST",
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'access-control-allow-origin': '*',
                    "authorization": `Bearer ${token}`
                },
                url: `${Apiurl()}/admin/reset-password-form/update`,
                data: formData
            };

            const response = await axios(option)
            navigate("/admin/reset-password")

        } catch (error) {
            console.error(error);
        }
    }

    //validations
    //validations list
    const getValidationsList = () => {
        const option = {
            method: "GET",
            headers: {
                "access-control-allow-origin": "*",
                "content-type": "application/json",
                "authorization": `Bearer ${token}`
            },
            url: `${Apiurl()}/admin/validation-rules`,
        };
        axios(option)
            .then((e) => {
                const data = e?.data?.data
                const uniqueValidationTypes = data
                    .map(item => item.validation_type)
                    .filter((value, index, self) => self.indexOf(value) === index).map(item => ({ label: item, value: item }));
                // const uniqueValidationTypes = [...new Set(data)];

                setValidationList(data)
                setValidationType(uniqueValidationTypes)
            })
            .catch((error) => {
                // navigate("/")
            });
    };
    useEffect(() => {
        getValidationsList()
    }, [])
    const validationRequired = [
        { label: "Yes", value: true },
        { label: "No", value: false },
    ]
    const handleAddRow = (setter, list) => {
        setter([...list, { id: Date.now(), selectValue: '', value: '' }]);
    };

    const handleRemoveRow = (setter, list, id) => {
        setter(list.filter(item => item.id !== id));
    };
    const handleCheckboxChange = (event) => {
        setShowValidations(event);
    };

    const handleValidationChange = (setter, list, id, field, value) => {
        setter(list.map(item => {
            if (item.id === id) {
                if (field == "type") {
                    return { id: item.id, type: value, value: '', unit: '', numberUpperLimit: '', numberLowerLimit: '', messageEnglish: '', messageHindi: '' };
                } else {
                    return { ...item, [field]: value };
                }
            }
            return item;
        }));
        setValidationError("");
    };
    return (
        <AdminDashboard>
            <div className='aod-outer'>
                <div className='ap-breadcrumb pt-4'>
                    <HomeIcon />Home  /  <span>Reset Password Form</span>
                </div>
                {/* Input fields update */}
                {editData?.id && <div className="aod-inner">
                    <div className={editData?.id ? "aod-bottom" : "aod-bottom"}>
                        <div className="aod-head text-center adm-head"><button onClick={() => navigate("/admin/reset-password")}><BsChevronLeft /> Back</button>{editData?.id ? "Update" : "Add"} Reset Password Form Field</div>
                        <div className="row py-4">
                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>

                                    <label className='mb-1 w-100'>
                                        English Name
                                    </label>
                                    <input className='w-100' placeholder='Enter English Name' type="text" onKeyUp={() => handleKeyUpLoginDetails("name")} value={name} onChange={(e) => setName(e?.target?.value)} />

                                    {nameError && <p className='validate-error'>{nameError}</p>}

                                </div>
                            </div>
                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>

                                    <label className='mb-1 w-100'>
                                        Hindi Name
                                    </label>
                                    <input className='w-100' placeholder='Enter Hindi Name' type="text" onKeyUp={() => handleKeyUpLoginDetails("nameHi")} value={nameHi} onChange={(e) => setNameHi(e?.target?.value)} />

                                    {nameHiError && <p className='validate-error'>{nameHiError}</p>}

                                </div>
                            </div>
                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>

                                    <label className='mb-1 w-100'>
                                        Placeholder English Name
                                    </label>
                                    <input className='w-100' placeholder='Enter Placeholder English Name' type="text" onKeyUp={() => handleKeyUpLoginDetails("pname")} value={pname} onChange={(e) => setPName(e?.target?.value)} />

                                    {pnameError && <p className='validate-error'>{pnameError}</p>}

                                </div>
                            </div>
                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>

                                    <label className='mb-1 w-100'>
                                        Placeholder Hindi Name
                                    </label>
                                    <input className='w-100' placeholder='Enter Placeholder Hindi Name' type="text" onKeyUp={() => handleKeyUpLoginDetails("pnameHi")} value={pnameHi} onChange={(e) => setPNameHi(e?.target?.value)} />

                                    {pnameHiError && <p className='validate-error'>{pnameHiError}</p>}

                                </div>
                            </div>

                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>
                                    <label className='mb-1 w-100'>
                                        Icon
                                    </label>
                                    <input type="file" name="partsuploadfile" id="profileimg" className="w-100 ad-cat-input-field-image" accept="image/*" onKeyUp={() => handleKeyUpLoginDetails("img")} onChange={(e) => handleFileChange(e)} />
                                    <label htmlFor="profileimg" className="ad-cat-input-upload-image p-0">
                                        <p className="ad-cat-text-upload-image">
                                            <span><UploadIcon /></span> Choose Icon
                                        </p>
                                    </label>
                                    {image?.name ? <p className='validate-error'>{image?.name}</p> : imageError ? <p className='validate-error'>{imageError}</p> : ""}

                                </div>
                            </div>
                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>
                                    <label className='mb-1 w-100'>Font Family</label>
                                    <Select
                                        className='menu_open_select'
                                        classNamePrefix='ad-menu_open_select_class'
                                        aria-label="Default select example"
                                        value={fontFamily}
                                        options={fontsList}
                                        onChange={(e) => { setFontFamily(e); setFontFamilyError(""); }}
                                        placeholder="Select Font Family"
                                        onMenuOpen={() => handleKeyUpLoginDetails("family")}
                                        onMenuClose={() => handleKeyUpLoginDetails("family")}
                                    />
                                    {fontFamilyError && <p className='validate-error'>{fontFamilyError}</p>}
                                </div>
                            </div>
                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>
                                    <label className='mb-1 w-100'>Font Color</label>
                                    <label className='adm-label-color'>
                                        {fontColor ? fontColor : <span>Select Font Color</span>}
                                        <input className='adm-color' placeholder='Enter Font Color' type="color" onKeyUp={() => handleKeyUpLoginDetails("color")} onBlur={() => handleKeyUpLoginDetails("color")} value={fontColor} onChange={(e) => setFontColor(e?.target?.value)} />
                                    </label>
                                    {fontColorError && <p className='validate-error'>{fontColorError}</p>}
                                </div>
                            </div>
                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>
                                    <label className='mb-1 w-100'>Font Size (in pixels)</label>
                                    <input className='w-100' placeholder='Enter Font Size' type="number" value={fontSize} onKeyUp={() => handleKeyUpLoginDetails("size")}
                                        onKeyDown={(e) => {
                                            if (e.key === '-' || e.key === '+' || e.key === 'e' || e.key === 'E') {
                                                e.preventDefault();
                                            }
                                        }}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (value > 0) {
                                                setFontSize(value);
                                                setFontSizeError("")
                                            } else {
                                                setFontSize("");

                                            }
                                        }} />
                                    {fontSizeError && <p className='validate-error'>{fontSizeError}</p>}
                                </div>
                            </div>
                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>
                                    <label className='mb-1 w-100'>Validations</label>
                                    <Select
                                        className='menu_open_select'
                                        classNamePrefix='ad-menu_open_select_class'
                                        aria-label="Default select example"
                                        value={showValidations}
                                        onChange={handleCheckboxChange}
                                        options={validationRequired}
                                        placeholder="Select "
                                    />
                                    {validationError && <p className='validate-error'>{validationError}</p>}
                                </div>

                            </div>
                            {showValidations?.value == true && (
                                <div className="col-md-12">
                                    <div className='row'>
                                        <div className='row-head-ad'>Prevent Data from Entering</div>

                                        {preventData.map((item, index) => (
                                            <div className='row ad-reg-res'>
                                                <div className="ad-col-width-1 aod-resp-tab">
                                                    <div className='suf-input-box aod-form-input mb-2'>
                                                        <Select
                                                            className='menu_open_select'
                                                            classNamePrefix='ad-menu_open_select_class'
                                                            aria-label="Default select example"
                                                            value={item.type}
                                                            onChange={(e) => handleValidationChange(setPreventData, preventData, item.id, 'type', e)}
                                                            options={validationsType}
                                                            placeholder="Select Type"
                                                        />
                                                    </div>
                                                </div>
                                                {
                                                    item?.type &&

                                                    <div className="ad-col-width-1 aod-resp-tab">
                                                        <div className='suf-input-box aod-form-input mb-2'>
                                                            <Select
                                                                className='menu_open_select'
                                                                classNamePrefix='ad-menu_open_select_class'
                                                                aria-label="Default select example"
                                                                value={item.unit}
                                                                onChange={(e) => handleValidationChange(setPreventData, preventData, item.id, 'unit', e)}
                                                                options={validationsList?.filter(i => i?.validation_type == item?.type?.value)?.map(i => ({ label: i?.name, value: i?.id }))}
                                                                placeholder="Select Validation"
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    item?.unit?.value ? <>
                                                        {
                                                            validationsList?.find(i => i?.id == item?.unit?.value)?.input_required == 1 ? <div className="ad-col-width-1 aod-resp-tab">
                                                                <div className='suf-input-box aod-form-input mb-2'>
                                                                    <input
                                                                        type="number"
                                                                        className='w-100'
                                                                        placeholder="Enter value"
                                                                        value={item.value}
                                                                        onChange={(e) => handleValidationChange(setPreventData, preventData, item.id, 'value', e.target.value)}
                                                                    />
                                                                </div>
                                                            </div> : validationsList?.find(i => i?.id == item?.unit?.value)?.input_required == 2 ? <div className="ad-col-width-1 ad-reg-btn aod-resp-tab">
                                                                <div className='suf-input-box aod-form-input mb-2'>
                                                                    <input
                                                                        type="number"
                                                                        className='w-50'
                                                                        placeholder="Lower limit"
                                                                        value={item?.numberLowerLimit}
                                                                        onChange={(e) => handleValidationChange(setPreventData, preventData, item.id, 'numberLowerLimit', e.target.value)}
                                                                    />
                                                                    <input
                                                                        type="number"
                                                                        className='w-50'
                                                                        placeholder="Upper limit"
                                                                        value={item?.numberUpperLimit}
                                                                        onChange={(e) => handleValidationChange(setPreventData, preventData, item.id, 'numberUpperLimit', e.target.value)}
                                                                    />
                                                                </div>
                                                            </div> : ""
                                                        }
                                                    </> : null
                                                }

                                                {item.unit?.value && (
                                                    <>
                                                        <div className="ad-col-width-1 aod-resp-tab">
                                                            <div className='suf-input-box aod-form-input mb-2'>
                                                                <input
                                                                    type="text"
                                                                    className='w-100'
                                                                    placeholder="Message English"
                                                                    value={item.messageEnglish}
                                                                    onChange={(e) => handleValidationChange(setPreventData, preventData, item.id, 'messageEnglish', e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="ad-col-width-1 aod-resp-tab">
                                                            <div className='suf-input-box aod-form-input mb-2'>
                                                                <input
                                                                    type="text"
                                                                    className='w-100'
                                                                    placeholder="Message Hindi"
                                                                    value={item.messageHindi}
                                                                    onChange={(e) => handleValidationChange(setPreventData, preventData, item.id, 'messageHindi', e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                                <div className='ad-col-width-btn ad-reg-btn'>
                                                    {
                                                        preventData.length > 1 &&
                                                        <span className='ad-reg-delete' onClick={() => handleRemoveRow(setPreventData, preventData, item.id)}><DeleteIcon /></span>

                                                    }
                                                    {
                                                        preventData.length == index + 1 && preventData.length < 7 &&
                                                        <span className='ad-reg-plus' onClick={() => handleAddRow(setPreventData, preventData)}>+</span>

                                                    }
                                                </div>
                                            </div>
                                        ))}

                                        <div className='row-head-ad'>Other Validations</div>
                                        {otherValidations.map((item, index) => (
                                            <div className='row ad-reg-res'>
                                                <div className="ad-col-width-1 aod-resp-tab">
                                                    <div className='suf-input-box aod-form-input mb-2'>
                                                        <Select
                                                            className='menu_open_select'
                                                            classNamePrefix='ad-menu_open_select_class'
                                                            aria-label="Default select example"
                                                            value={item.type}
                                                            onChange={(e) => handleValidationChange(setOtherValidations, otherValidations, item.id, 'type', e)}
                                                            options={validationsType}
                                                            placeholder="Select Type"
                                                        />
                                                    </div>
                                                </div>

                                                {
                                                    item?.type && <div className="ad-col-width-1 aod-resp-tab">
                                                        <div className='suf-input-box aod-form-input mb-2'>
                                                            <Select
                                                                className='menu_open_select'
                                                                classNamePrefix='ad-menu_open_select_class'
                                                                aria-label="Default select example"
                                                                value={item.unit}
                                                                onChange={(e) => handleValidationChange(setOtherValidations, otherValidations, item.id, 'unit', e)}
                                                                options={validationsList?.filter(i => i?.validation_type == item?.type?.value)?.map(i => ({ label: i?.name, value: i?.id }))}
                                                                placeholder="Select Validation"
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    item?.unit?.value ? <>
                                                        {
                                                            validationsList?.find(i => i?.id == item?.unit?.value)?.input_required == 1 ? <div className="ad-col-width-1 aod-resp-tab">
                                                                <div className='suf-input-box aod-form-input mb-2'>
                                                                    <input
                                                                        type="number"
                                                                        className='w-100'
                                                                        placeholder="Enter value"
                                                                        value={item.value}
                                                                        onChange={(e) => handleValidationChange(setOtherValidations, otherValidations, item.id, 'value', e.target.value)}
                                                                    />
                                                                </div>
                                                            </div> : validationsList?.find(i => i?.id == item?.unit?.value)?.input_required == 2 ? <div className="ad-col-width-1 ad-reg-btn aod-resp-tab">
                                                                <div className='suf-input-box aod-form-input mb-2'>
                                                                    <input
                                                                        type="number"
                                                                        className='w-50'
                                                                        placeholder="Lower limit"
                                                                        value={item?.numberLowerLimit}
                                                                        onChange={(e) => handleValidationChange(setOtherValidations, otherValidations, item.id, 'numberLowerLimit', e.target.value)}
                                                                    />
                                                                    <input
                                                                        type="number"
                                                                        className='w-50'
                                                                        placeholder="Upper limit"
                                                                        value={item?.numberUpperLimit}
                                                                        onChange={(e) => handleValidationChange(setOtherValidations, otherValidations, item.id, 'numberUpperLimit', e.target.value)}
                                                                    />
                                                                </div>
                                                            </div> : ""
                                                        }
                                                    </> : null
                                                }

                                                {item.unit?.value && (
                                                    <>
                                                        <div className="ad-col-width-1 aod-resp-tab">
                                                            <div className='suf-input-box aod-form-input mb-2'>
                                                                <input
                                                                    type="text"
                                                                    className='w-100'
                                                                    placeholder="Message English"
                                                                    value={item.messageEnglish}
                                                                    onChange={(e) => handleValidationChange(setOtherValidations, otherValidations, item.id, 'messageEnglish', e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="ad-col-width-1 aod-resp-tab">
                                                            <div className='suf-input-box aod-form-input mb-2'>
                                                                <input
                                                                    type="text"
                                                                    className='w-100'
                                                                    placeholder="Message Hindi"
                                                                    value={item.messageHindi}
                                                                    onChange={(e) => handleValidationChange(setOtherValidations, otherValidations, item.id, 'messageHindi', e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                                <div className='ad-col-width-btn ad-reg-btn'>
                                                    {
                                                        otherValidations.length > 1 &&
                                                        <span className='ad-reg-delete' onClick={() => handleRemoveRow(setOtherValidations, otherValidations, item.id)}><DeleteIcon /></span>

                                                    }
                                                    {
                                                        otherValidations.length == index + 1 && otherValidations.length < 7 &&
                                                        <span className='ad-reg-plus' onClick={() => handleAddRow(setOtherValidations, otherValidations)}>+</span>

                                                    }
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>)}
                        </div>
                        <div className="row ad-cat-row">
                            <div className="row ad-cat-row">

                                <div className="w-auto gap-4 d-flex">
                                    <button onClick={() => updateCategory()}>
                                        Update
                                    </button> </div>

                            </div>

                        </div>

                    </div>
                </div>}
                {/* Input fields update end */}

                {/* Buttons Update */}
                {editBtnData?.id && <div className="aod-inner" >
                    <div className={editBtnData?.id ? "aod-bottom" : "aod-bottom"}>
                        <div className="aod-head text-center adm-head"><button onClick={() => navigate("/admin/reset-password")}><BsChevronLeft /> Back</button>{editBtnData?.id ? "Update" : "Add"} Reset Password Form Buttons</div>
                        <div className="row py-4">
                            <div className="col-md-3"></div>
                            <div className="col-md-6">
                                <div style={{ backgroundColor: btnBackgroundColor, fontSize: `${btnFontSize}px`, color: btnFontColor, fontFamily: btnFontFamily?.label, padding: "10px", textAlign: "center" }}>
                                    {btnName} {btnNameHi}
                                </div>
                            </div>
                            <div className="col-md-3"></div>
                        </div>
                        <div className="row py-4">
                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>

                                    <label className='mb-1 w-100'>
                                        English Name
                                    </label>
                                    <input className='w-100' placeholder='Enter English Name' type="text" onKeyUp={() => handleKeyUpLoginDetailsBtn("name")} value={btnName} onChange={(e) => setBtnName(e?.target?.value)} />

                                    {btnNameError && <p className='validate-error'>{btnNameError}</p>}

                                </div>
                            </div>
                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>

                                    <label className='mb-1 w-100'>
                                        Hindi Name
                                    </label>
                                    <input className='w-100' placeholder='Enter Hindi Name' type="text" onKeyUp={() => handleKeyUpLoginDetailsBtn("nameHi")} value={btnNameHi} onChange={(e) => setBtnNameHi(e?.target?.value)} />

                                    {btnNameHiError && <p className='validate-error'>{btnNameHiError}</p>}

                                </div>
                            </div>

                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>
                                    <label className='mb-1 w-100'>Font Family</label>
                                    <Select
                                        className='menu_open_select'
                                        classNamePrefix='ad-menu_open_select_class'
                                        aria-label="Default select example"
                                        value={btnFontFamily}
                                        options={fontsList}
                                        onChange={(e) => { setBtnFontFamily(e); setBtnFontFamilyError(""); }}
                                        placeholder="Select Font Family"
                                        onMenuOpen={() => handleKeyUpLoginDetailsBtn("family")}
                                        onMenuClose={() => handleKeyUpLoginDetailsBtn("family")}
                                    />
                                    {btnFontFamilyError && <p className='validate-error'>{btnFontFamilyError}</p>}
                                </div>
                            </div>
                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>
                                    <label className='mb-1 w-100'>Font Color</label>
                                    <label className='adm-label-color'>
                                        {btnFontColor ? btnFontColor : <span>Select Font Color</span>}
                                        <input className='adm-color' placeholder='Enter Font Color' type="color" onKeyUp={() => handleKeyUpLoginDetailsBtn("color")} onBlur={() => handleKeyUpLoginDetailsBtn("color")} value={btnFontColor} onChange={(e) => setBtnFontColor(e?.target?.value)} />
                                    </label>
                                    {btnFontColorError && <p className='validate-error'>{btnFontColorError}</p>}
                                </div>
                            </div>
                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>
                                    <label className='mb-1 w-100'>Font Size (in pixels)</label>
                                    <input className='w-100' placeholder='Enter Font Size' type="number" value={btnFontSize} onKeyUp={() => handleKeyUpLoginDetailsBtn("size")}
                                        onKeyDown={(e) => {
                                            if (e.key === '-' || e.key === '+' || e.key === 'e' || e.key === 'E') {
                                                e.preventDefault();
                                            }
                                        }}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (value > 0) {
                                                setBtnFontSize(value);
                                                setBtnFontSizeError("")
                                            } else {
                                                setBtnFontSize("");

                                            }
                                        }} />
                                    {btnFontSizeError && <p className='validate-error'>{btnFontSizeError}</p>}
                                </div>
                            </div>
                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>
                                    <label className='mb-1 w-100'>Background Color</label>
                                    <label className='adm-label-color'>
                                        {btnBackgroundColor ? btnBackgroundColor : <span>Select Background Color</span>}
                                        <input className='adm-color' placeholder='Enter Font Color' type="color" onKeyUp={() => handleKeyUpLoginDetailsBtn("bgcolor")} onBlur={() => handleKeyUpLoginDetailsBtn("bgcolor")} value={btnBackgroundColor} onChange={(e) => setBtnBackgroundColor(e?.target?.value)} />
                                    </label>
                                    {btnBackgroundColorError && <p className='validate-error'>{btnBackgroundColorError}</p>}
                                </div>
                            </div>

                        </div>
                        <div className="row ad-cat-row">
                            <div className="row ad-cat-row">

                                <div className="w-auto gap-4 d-flex">
                                    <button onClick={() => updateCategoryBtn()}>
                                        Update
                                    </button> </div>

                            </div>

                        </div>

                    </div>
                </div>}
                {/* Button update end */}
            </div>
        </AdminDashboard>
    )
}

export default AdminResetPasswordAdd
