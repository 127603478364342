import React, { useEffect } from 'react'
import HomeBanner from '../../components/Home/HomeBanner/HomeBanner';
import HomeCategory from '../../components/Home/HomeCategory/HomeCategory';
import HomeExplore from '../../components/Home/HomeExplore/HomeExplore';
import HomeStepByStep from '../../components/Home/HomeStepByStep/HomeStepByStep';
import "../../components/Home/Home.css"
import { ClearLocalStorage } from '../../Utils/Utils';
import MainLayout from '../../components/MainLayout/MainLayout';
const Home = () => {
  useEffect(() => {
    ClearLocalStorage()
  }, [])
  return (
    <>
      <MainLayout>
        <HomeBanner />
        <HomeCategory />
        {/* <HomeExplore /> */}
        <HomeStepByStep />
      </MainLayout>
    </>
  )
}

export default Home
