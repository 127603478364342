import React from 'react'
import AdminDashboard from '../AdminDashboard/AdminDashboard'
const DashboardLayout = () => {
  return (
    <AdminDashboard>
    <h1 className='m-5'>Hello Admin</h1>
    </AdminDashboard>
  )
}
export default DashboardLayout
